import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { RENDER_URL, LOCALISATION_ARRAY } from "../common/Constants";
import LocalizedStrings from "react-localization";


function ConfirmContainer(props) {


    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    let selectedBoxType = context.login.setSelectedBoxOption;

    //Congratulations, you are about to create your very own Victory Eagle Avatar.You decided to Spin & Choose, which means that you will get to select oneVictory Eagle Avatar from five available options. Create the first Avatar by clicking the button below. After that, click "Spin Again" to create four more Victory Eagle Avatars to choose from.

    useEffect(() => {



    }, []);




    const onCreateButtonClick = () => {

        let selectedBoxType = context.login.setSelectedBoxOption;
        if (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) {
            _history(RENDER_URL.MYSTERY_BOX_URL);
        } else {
            _history(RENDER_URL.SPIN_BOX_URL);
        }
    }




    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">

                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row" style={{ padding: '10px' }}>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <p className="payment_success_title" style={{fontSize:'25px',lineHeight:'40px'}}>{WEB_STRINGS.CONFIRM_TITLE}</p>
                                                    <p className="payment_success_desc">
                                                        {
                                                            (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) ? `${WEB_STRINGS.CONFIRM_DESC_2}` : `${WEB_STRINGS.CONFIRM_DESC_1}`
                                                        }

                                                    </p>

                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.CREATE_MY_AVATAR} onClick={() => { onCreateButtonClick() }} />

                                                    <p className="text-center confirm_policy">
                                                        {WEB_STRINGS.DISCLAIMER}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default ConfirmContainer;
import React, { useState,useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import ClaimCardContainer from "./ClaimcardContainer";
import { SET_SELECTED_BOX_OPTION, SET_SELECTED_ORDER_OPTION, AVATAR_CHOICE } from "../common/Constants";
import { GlobalDispatchContext, GlobalContext } from "../context/Context";
import { LOCALISATION_ARRAY } from "../common/Constants";
import LevelContainer from "../Level/LevelContainer";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import LocalizedStrings from "react-localization";

function ClaimContainer() {


    //this is te
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [boxTypeList, setBoxTypeList] = useState(context.login.AVATAR_CHOICE);
    const [selectedBoxType, setSelectedBoxType] = useState(boxTypeList ? boxTypeList.MYSTERY : {})

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    const [language_code, setLanguageCode] = useState(context.login.LANGUAGE_CODE)
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    useEffect(() => {
        setLanguageCode(context.login.LANGUAGE_CODE);
    }, [context.login.LANGUAGE_CODE])

    const onWhichAvatarType = (obj_key) => {
        let id_value = obj_key;
        setSelectedBoxType(id_value);
    }

    const onNextAction = () => {

        dispatch({
            type: SET_SELECTED_BOX_OPTION,
            payload: selectedBoxType,
        });

        _history("/payment")

    }

    const onBack = () => {
        _history('/order_optn');
    }

    const calculateTotal = () => {

        let selectedOrderOption = context.login.setSelectedOrderOption;
        let selectedChoiceOption = selectedBoxType;
        let Total = parseFloat(selectedOrderOption.price) + parseFloat(selectedChoiceOption.price);
        console.log(">>>total", Total);
        return Total;

    }

    const getCategoryPrice = () => {
        let selectedOrderOption = context.login.setSelectedOrderOption;
        console.log("getcatrogyr price", selectedOrderOption);
        return selectedOrderOption.price;
    }
    const getChoicePrice = () => {
        let selectedChoiceOption = selectedBoxType;
        return selectedChoiceOption.price;
    }

    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12  main_section">
                            <LogoHeader></LogoHeader>
                            {/* <div className="row" style={{ marginTop: '50px' }}>
                                <div className='col-sm-12'>
                                    <LevelContainer levelCount={5} completed={2}></LevelContainer>
                                </div>
                            </div> */}

                            <div className="row">
                                <h4 className="main_titles">{WEB_STRINGS.CLAIM_TITLE}</h4>
                            </div>


                            <div className="row align-center" style={{ marginTop: '3%' }}>


                                <ClaimCardContainer title={boxTypeList.MYSTERY.name} price={boxTypeList.MYSTERY.price} image={boxTypeList.MYSTERY.image} description={language_code == 'pt' ? boxTypeList.MYSTERY.description_pt : boxTypeList.MYSTERY.description} isSelected={selectedBoxType} id={boxTypeList.MYSTERY} onclickfn={() => { onWhichAvatarType(boxTypeList.MYSTERY) }} />
                                <ClaimCardContainer title={boxTypeList.SPIN.name} price={boxTypeList.SPIN.price} image={boxTypeList.SPIN.image} description={language_code == 'pt' ? boxTypeList.MYSTERY.description_pt : boxTypeList.SPIN.description} isSelected={selectedBoxType} id={boxTypeList.SPIN} onclickfn={() => { onWhichAvatarType(boxTypeList.SPIN) }} />


                            </div>


                            <div className="row btn_pad">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                    <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_back" label={WEB_STRINGS.BACK} onClick={() => { onBack() }}></Button>
                                    <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_continue" label={WEB_STRINGS.CONTINUE} onClick={() => { onNextAction() }}></Button>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order_total_price">
                                    <p className="p_pricing">{WEB_STRINGS.COMMON} :€{getCategoryPrice()}</p>
                                    <p className="p_pricing">{WEB_STRINGS.CHOICE} :€{getChoicePrice()}</p>
                                    <hr></hr>
                                    <p className="p_pricing">Total  : €{calculateTotal()}</p>
                                </div>
                            </div>



                            {/*                        <div className="row signin_foot">
                                <hr className="primary-color"></hr>
                                <p> ©2023 interverse -SL Benefica Product All rights reserved</p>
                            </div>
 */}


                        </div>
                        <div className="col-sm-4 image_choose">
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}
export default ClaimContainer
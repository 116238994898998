import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { saveAs } from 'file-saver';
import { RENDER_URL, LOCALISATION_ARRAY, FROM_DASHBOARD, START_LOADING, STOP_LOADING } from "../common/Constants";
import LocalizedStrings from "react-localization";
import { useSwipeable } from 'react-swipeable';
import { dashboardAttributes } from "./DashboardService";
import ReactGA from "react-ga4";

function DashboardContainer() {

    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);

    const _history = useNavigate();
    const location = useLocation();
    const [mysteryAvatarList, setMysteryAvatarList] = useState([])
    const [mysteryAvatar, setMysteryAvatar] = useState()


    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const [showProfileImage, setProfileImage] = useState(true);

    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        if (context.login.userData) {
            loadMyAvatars();
            dispatch({
                type: FROM_DASHBOARD,
                payload: false,
            });
        }
        else {
            dispatch({
                type: FROM_DASHBOARD,
                payload: true,
            });
            _history(RENDER_URL.LOGIN_NEW_URL, { state: { fromDashboard: true } });
        }

        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Dashboard" });

    }, []);


    const loadMyAvatars = () => {

        dispatch({
            type: START_LOADING,
        });

        dashboardAttributes().then((response) => {

            if (response.data.success) {
                let avatarData = response.data.attributes.avatars;
                if (avatarData) {
                    setMysteryAvatarList(avatarData);
                    setCurrentCount(0);
                    setMysteryAvatar(avatarData[0]);
                }
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {
            console.log(error, "ERROR");
            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const onLoadNext = () => {
        let count = currentCount;
        count++;
        if ((count) <= (mysteryAvatarList.length - 1)) {
            setCurrentCount(count);
            console.log(mysteryAvatarList[count]);
            setMysteryAvatar(mysteryAvatarList[count]);
        }
    }


    const onLoadPrev = () => {
        let count = currentCount;
        count--;
        if ((count) >= 0) {
            setCurrentCount(count);
            setMysteryAvatar(mysteryAvatarList[count]);
        }
    }

    const renderAttributes = () => {

        if (mysteryAvatar?.metadata) {
            let count = 0;
            return (
                Object.entries(mysteryAvatar?.metadata).map(([index, object]) => {
                    return <div key={index} className="attribute">
                        <span style={{ color: 'red' }}>{object.name}</span>
                        <span style={{ color: 'black' }}>{object.value ? object.value : 'none'}</span>
                        <span style={{ color: 'black' }}>{object.proportion ? `${object.proportion} %` : ''}</span>
                    </div>
                })
            )
        }

    }

    const onDownloadClick = () => {
        saveAs(showProfileImage ? mysteryAvatar.profile_image : mysteryAvatar.booth_image, `${mysteryAvatar?.name}_${mysteryAvatar?.avatar_id}`)
    }


    const onOrderAgain = () => {

        _history(RENDER_URL.ORDER_OPT_URL);

    }

    const handlers = useSwipeable({
        onSwipedLeft: () => setProfileImage(!showProfileImage),
        onSwipedRight: () => setProfileImage(!showProfileImage),
        swipeDuration: 1000,
        trackMouse: true
    });

    
    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-sm-12 main_section">
                            <LogoHeader showProfile={true} ></LogoHeader>

                            <div className="row">
                                {mysteryAvatarList.length > 0 ? <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2" style={{ marginTop: '2%' }}>

                                    <Button icon="pi pi-angle-left" rounded outlined severity="secondary" aria-label="Cancel" className="button_main_left" onClick={() => { onLoadPrev() }} />
                                    <Button icon="pi pi-angle-right" rounded outlined severity="secondary" aria-label="Cancel" className="button_main_right" onClick={() => { onLoadNext() }} />
                                    <div className="row">
                                        <div className="col-lg-6 text-center" {...handlers} >
                                            <div className="card shadow-lg" id="xxx" style={{ padding: '10px', height: '100%', minHeight: '500px' }}>

                                                {mysteryAvatarList.length > 1 && <div className="row mobile_next">
                                                    <Button icon="pi pi-angle-left" rounded outlined severity="secondary" aria-label="Cancel" style={{ marginLeft: '5%' }} onClick={() => { onLoadPrev() }} />
                                                    <Button icon="pi pi-angle-right" rounded outlined severity="secondary" aria-label="Cancel" style={{ marginLeft: '35%' }} onClick={() => { onLoadNext() }} />
                                                </div>
                                                }

                                                {
                                                    showProfileImage ?
                                                        <div className="bg-image" style={{ background: `url(${mysteryAvatar?.profile_image})` }} onClick={() => { setProfileImage(!showProfileImage) }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                <img src="../assets/images/slide.png" width="12%" height="auto" style={{ float: 'left', paddingLeft: '10px', paddingTop: '10px' }} />
                                                                <div style={{ bottom: '20px', left: '0', width: '100%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                    <span class={showProfileImage ? "dot_selected" : "dot"}></span>
                                                                    <span class="dot"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="bg-image booth" style={{ background: `url(${mysteryAvatar?.booth_image})` }} onClick={() => { setProfileImage(!showProfileImage) }} >
                                                            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                <div style={{ bottom: '0', left: '0', width: '90%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                    <span class="dot"></span>
                                                                    <span class="dot_selected"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                                <div className="download_bottom">
                                                    <div>
                                                        <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.DOWNLOAD_IMAGES} onClick={() => { onDownloadClick() }}></Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="card shadow-lg">

                                                    {/*  <Button icon="pi pi-angle-left" rounded text severity="danger" aria-label="Cancel" style={{ float: 'left', position: 'absolute', marginTop: '34%', marginLeft: '-25px', zIndex: '3000' }} onClick={() => { onLoadPrev() }} />
                                                    <Button icon="pi pi-angle-right" rounded text severity="danger" aria-label="Cancel" style={{ float: 'right', position: 'absolute', marginTop: '34%', marginLeft: '90%', zIndex: '3000' }} onClick={() => { onLoadNext() }} />
 */}
                                                    <div className="attribute_div">
                                                        <div className="row back_white">
                                                            <div className="col-auto p-0">
                                                                <img src="../assets/images/logo.png" width='50px' />
                                                            </div>
                                                            <div className="col p-0">
                                                                <h6 style={{ marginTop: '2%' }}>{mysteryAvatar?.name}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="row margin_test">
                                                            <div className="col-sm-12">
                                                                <div style={{ paddingRight: '40px', position: 'relative' }}>
                                                                    <div className='attributes'>
                                                                        {
                                                                            renderAttributes()
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="follow_title">{WEB_STRINGS.FOLLOW_THESE_STEPS}</div>
                                                        <ol>
                                                            <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_1}</li>
                                                            <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }} >{WEB_STRINGS.STEP_2}</li>
                                                            <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_3}</li>
                                                        </ol>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <Button label={WEB_STRINGS.FOLLOW_US} className="social_button twitter" onClick={() => { window.location.href = 'https://twitter.com/VictorySLB' }} icon="pi pi-twitter" />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <Button label={WEB_STRINGS.FOLLOW_US} className="social_button insta" icon="pi pi-instagram" onClick={() => { window.location.href = 'https://instagram.com/victory_slb' }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <Button label={WEB_STRINGS.ORDER_AGAIN} className="social_button" onClick={() => { onOrderAgain() }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className="card shadow-lg">
                                                <div class="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                            <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                            <div className="sign_header">
                                                                {WEB_STRINGS.NO_AVATARS_TO_DISPLAY}
                                                            </div>
                                                            <br></br>
                                                            <Button label={WEB_STRINGS.ORDER_NOW} className="social_button" severity="danger" onClick={() => { onOrderAgain() }} />
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                                    </div>
                                }


                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DashboardContainer


import {
    LOGIN_SUCCESS,
    LOGOUT,
    APP_TOKEN,
    SET_COLLECTION,
    SET_ASSET,
    SET_ITEM,
    USER_DATA,
    SET_SELECTED_ORDER_OPTION,
    SET_SELECTED_BOX_OPTION,
    PAYMENT_UUID,
    AVATAR_TYPE,
    AVATAR_TYPES,
    AVATAR_CHOICE,
    LANGUAGE_CODE,
    COUPON_CODE,
    FROM_DASHBOARD,
    TWITTER_USER
} from "../common/Constants";

import { ManageLocalStorage } from "../core/LocalStorage"
import { ManageSessionStorage } from "../core/SessionStorage"




let applicationToken = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : null

let regexPattern = new RegExp("true");

export const loginInitialState = {
    isLoggedIn: ManageLocalStorage.get(USER_DATA)
        ? true
        : false,
    TWITTER_USER: ManageLocalStorage.get(TWITTER_USER) ? JSON.parse(ManageLocalStorage.get(TWITTER_USER)) : {},
    FROM_DASHBOARD: ManageSessionStorage.get(FROM_DASHBOARD) ? regexPattern.test(ManageLocalStorage.get(FROM_DASHBOARD)) : false,
    LANGUAGE_CODE: ManageLocalStorage.get(LANGUAGE_CODE) ? ManageLocalStorage.get(LANGUAGE_CODE) : 'en',
    AVATAR_TYPES: ManageLocalStorage.get(AVATAR_TYPES) ? JSON.parse(ManageLocalStorage.get(AVATAR_TYPES)) : {},
    AVATAR_CHOICE: ManageLocalStorage.get(AVATAR_CHOICE) ? JSON.parse(ManageLocalStorage.get(AVATAR_CHOICE)) : {},
    userData: ManageLocalStorage.get(USER_DATA) ? JSON.parse(ManageLocalStorage.get(USER_DATA)) : undefined,
    setSelectedOrderOption: ManageLocalStorage.get(SET_SELECTED_ORDER_OPTION) ? JSON.parse(ManageLocalStorage.get(SET_SELECTED_ORDER_OPTION)) : {},
    setSelectedBoxOption: ManageLocalStorage.get(SET_SELECTED_BOX_OPTION) ? JSON.parse(ManageLocalStorage.get(SET_SELECTED_BOX_OPTION)) : {},
    PAYMENT_UUID: ManageLocalStorage.get(PAYMENT_UUID) ? ManageLocalStorage.get(PAYMENT_UUID) : null
};



export const LoginReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return loginInitialState
    }


    switch (action.type) {

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userData: action.payload
            };

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userData: null,
            };
        case SET_SELECTED_ORDER_OPTION:
            ManageLocalStorage.set(SET_SELECTED_ORDER_OPTION, action.payload)
            return {
                ...state,
                setSelectedOrderOption: action.payload
            };
        case SET_SELECTED_BOX_OPTION:
            ManageLocalStorage.set(SET_SELECTED_BOX_OPTION, action.payload)
            return {
                ...state,
                setSelectedBoxOption: action.payload
            }
        case PAYMENT_UUID:
            return {
                ...state,
                PAYMENT_UUID: action.payload
            }
        case AVATAR_TYPES:
            return {
                ...state,
                AVATAR_TYPES: action.payload
            }
        case AVATAR_CHOICE:
            return {
                ...state,
                AVATAR_CHOICE: action.payload
            }
        case LANGUAGE_CODE:
            ManageLocalStorage.set(LANGUAGE_CODE, action.payload);
            return {
                ...state,
                LANGUAGE_CODE: action.payload
            }
        case COUPON_CODE:
            return {
                ...state,
                COUPON_CODE: action.payload
            }
        case FROM_DASHBOARD:
            ManageSessionStorage.set(FROM_DASHBOARD, action.payload)
            return {
                ...state,
                FROM_DASHBOARD: action.payload
            }
        case TWITTER_USER:
            ManageLocalStorage.set(TWITTER_USER, action.payload)
                console.log(">>>>>",action.payload);
            return {
                ...state,
                TWITTER_USER: action.payload
            }
        default:
            return state;
    }
};
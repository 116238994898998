import { httpCall,httpFormCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"


export const login = params => {
    return httpFormCall({
        url: API_URL.RESET_API,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const changePassword = params => {
    return httpFormCall({
        url: API_URL.RESET_API,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

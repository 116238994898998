import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import FooterContainer from "../footer/FooterContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { loginApi, signUpApi } from "../SignIn/SignService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, USER_DATA, CHOOSE_OPTION, USER_TOKEN, RENDER_URL, LOCALISATION_ARRAY, FROM_DASHBOARD } from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Toast } from "primereact/toast";
import LocalizedStrings from "react-localization";
import ReactGA from "react-ga4";

function LoginNewContainer(props) {

    const toast = useRef(null);
    const _history = useNavigate();
    const location = useLocation();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const [fromDashboard, setIsFromDashboard] = useState(location.state?.fromDashboard ? location.state?.fromDashboard : false);

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");
    const [error_msg, setErrorMsg] = useState()

    useEffect(() => {

        let userData = context.login.userData;
        let isDashboard = context.login.FROM_DASHBOARD;

        if (userData) {
            if (fromDashboard || isDashboard) {
                dispatch({
                    type: FROM_DASHBOARD,
                    payload: true,
                });
                _history(RENDER_URL.DASHBOARD);
            }
            else {
                onNext();
            }
        }

        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Login" });

    }, []);


    const showSuccess = (severity, summary, messageTo) => {
        toast.current.show({ severity: `${severity}`, summary: `${summary}`, detail: `${messageTo}`, life: 3000 });
    }

    const clearValidation = () => {
        setUserError("");
        setPasswordError("");
    }

    const onSignInAction = () => {
        clearValidation();
        if (username.length <= 0) {
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else if (!validateEmail(username)) {
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else if (password.length < 8) {
            setPasswordError(WEB_STRINGS.PASSWORD_VALIDATION);
            return;
        }
        else {
            backgroundLogin(username, password);
        }
    }


    const backgroundLogin = (username, password) => {

        let P_UUID = context.login.PAYMENT_UUID;


        let param = {
            email: username,
            password: password,
            payment_id: P_UUID
        }
        loginApi(param).then((response) => {
            if (response.data.success) {
                let userData = {
                    user: response.data.user,
                    uuid: response.data.uuid,
                    id: response.data.uuid.split("BFCA_")[1],
                    token: response.data.token
                }

                ManageLocalStorage.set(USER_DATA, userData);
                ManageLocalStorage.set(USER_TOKEN, userData.token);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });
                onNext();
            }
            else {
                setErrorMsg(WEB_STRINGS.ERROR_IN_LOGIN);
            }
        }).catch((error) => {
            console.log("ERROR", error);
        })

    }


    const onNext = () => {
        let isDashboard = context.login.FROM_DASHBOARD;

        if (fromDashboard || isDashboard) {

            _history(RENDER_URL.DASHBOARD)

        } else {
            let selectedBoxType = context.login.setSelectedBoxOption;
            
            if (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) {
                _history('/confirm');
            } else {
                _history('/confirm');
            }
        }

    }


    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <Toast ref={toast} />
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                    <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="sign_header">{WEB_STRINGS.WELCOME_BACK}</div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 px-2">
                                                    <div class="form-group">
                                                        <label for="exampleFormControlInput1">Email</label>
                                                        <input type="email" class="form-control form-control-md" id="exampleFormControlInput1" placeholder={WEB_STRINGS.EMAIL_PLACEHOLDER} onChange={(e) => setUsername(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{username_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="inputPassword2" class="sr-only">Password</label>
                                                        <input type="password" class="form-control" id="inputPassword2" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                        <small id="password-help" className="p-error block">{password_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: '15px', marginBottom: '20px' }}>
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.LOGIN} onClick={() => { onSignInAction() }} />
                                                </div>
                                                {
                                                    error_msg && <div className="form-group">
                                                        <span className="p-float-label text-center">
                                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                                        </span>
                                                    </div>
                                                }

                                                <div className="row text-center">
                                                    <p>
                                                        {WEB_STRINGS.DONT_HAVE_AN_ACCOUNT}<a href={RENDER_URL.SIGNIN_URL} target="_self" style={{ marginLeft: '3px' }}>{WEB_STRINGS.CLICK_HERE_TO_REGISTER}</a>
                                                    </p>
                                                    <p>
                                                        <a href={RENDER_URL.FORGOT_CONTAINER} target="_self" style={{ marginLeft: '3px' }}>{WEB_STRINGS.RESET_PASSWORD_BTN}</a>
                                                    </p>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginNewContainer;
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import OrderCard from "./OrderCard";
import { useNavigate } from "react-router-dom";
import { AVATAR_CHOICE, AVATAR_TYPES, SET_SELECTED_ORDER_OPTION, START_LOADING, STOP_LOADING, LOCALISATION_ARRAY, CHOOSE_OPTION_DETAILS } from "../common/Constants";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import LevelContainer from "../Level/LevelContainer";
import UnLeadHeader from "../common/UnLeadHeader";
import { getPricingDetails } from "./OrderService";
import { ManageLocalStorage } from "../core/LocalStorage";
import LogoHeader from "../common/LogoHeader";
import LocalizedStrings from 'react-localization';
import ReactGA from "react-ga4";

function OrderOptionContainer() {



    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)
    const [_avatarType, setAvatarType] = useState(undefined)
    const [selectedAvatarType, setselectedAvatarType] = useState(_avatarType ? _avatarType.COMMON : {});
    const [count, setCount] = useState(0);
    const [showDetailView, setShowDetailView] = useState(false);
    const _history = useNavigate();

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    const [language_code, setLanguageCode] = useState(context.login.LANGUAGE_CODE)
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    useEffect(() => {

        setLanguageCode(context.login.LANGUAGE_CODE);

    }, [context.login.LANGUAGE_CODE])

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "AvatarType" });
    },[])


    const onNext = () => {
        dispatch({
            type: SET_SELECTED_ORDER_OPTION,
            payload: selectedAvatarType,
        });
        _history("/claim")
    }

    useEffect(() => {

        loadPricingFromApi();


    }, []);

    const loadPricingFromApi = () => {

        dispatch({
            type: START_LOADING,
        });


        getPricingDetails().then((response) => {
            if (response.status == 200) {
                console.log(response);

                let _AVATAR_CATEGORY_TYPE = response.data.types
                let _AVATAR_CHOICE = response.data.choices;

                console.log("type", _AVATAR_CATEGORY_TYPE);
                console.log("choice", _AVATAR_CHOICE);

                setAvatarType(_AVATAR_CATEGORY_TYPE);
                setselectedAvatarType(_AVATAR_CATEGORY_TYPE.RARE);

                dispatch({
                    type: AVATAR_TYPES,
                    payload: _AVATAR_CATEGORY_TYPE,
                })

                dispatch({
                    type: AVATAR_CHOICE,
                    payload: _AVATAR_CHOICE,
                })

                ManageLocalStorage.set(AVATAR_TYPES, _AVATAR_CATEGORY_TYPE);
                ManageLocalStorage.set(AVATAR_CHOICE, _AVATAR_CHOICE);

                dispatch({
                    type: STOP_LOADING,
                });

            }
        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
            console.log("Error", error)
        })
    }


    const onWhichAvatarType = (obj_key) => {
        setCount(count + 1);
        let id_value = obj_key;
        setselectedAvatarType(id_value);
    }

    const onCancel = () => {
        window.location.href = "https://benfica.interverse.ai/";
    }

    const renderBullets = (dataArray) => {
        console.log(dataArray);
        return (
            Object.entries(dataArray).map(([index, object]) => {
                return <li className="payment_success_desc">{object}</li>
            }))
    }

    const showDesc = () => {
        if(!showDetailView){
            setShowDetailView(false);
            setTimeout(() => {
                setShowDetailView(true);
            }, 400)
        }
    }

    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            {/* <div className="row" style={{ marginTop: '50px' }}>
                                <div className='col-sm-12'>
                                    <LevelContainer levelCount={5} completed={1}></LevelContainer>
                                </div>
                            </div> */}

                            <div className="row">
                                <h4 className="main_titles">{WEB_STRINGS.ORDER_TITLE}</h4>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-3">
                                    <div className="row align-center" style={{ marginTop: '3%' }}>
                                        {
                                            (_avatarType != undefined) ? <>
                                                <OrderCard title={_avatarType.COMMON.name} description={language_code == 'pt' ? _avatarType.COMMON.description_pt : _avatarType.COMMON.description} image={_avatarType.COMMON.image} price={_avatarType.COMMON.price} isSelected={selectedAvatarType} id={_avatarType.COMMON} onclickfn={() => { onWhichAvatarType(_avatarType.COMMON) }} oninfoClick={() => { showDesc() }} />
                                                <OrderCard title={_avatarType.RARE.name} description={language_code == 'pt' ? _avatarType.RARE.description_pt : _avatarType.RARE.description} image={_avatarType.RARE.image} price={_avatarType.RARE.price} isSelected={selectedAvatarType} id={_avatarType.RARE} onclickfn={() => { onWhichAvatarType(_avatarType.RARE) }} oninfoClick={() => { showDesc() }} />
                                                <OrderCard title={_avatarType.EPIC.name} description={language_code == 'pt' ? _avatarType.EPIC.description_pt : _avatarType.EPIC.description} image={_avatarType.EPIC.image} price={_avatarType.EPIC.price} isSelected={selectedAvatarType} id={_avatarType.EPIC} onclickfn={() => { onWhichAvatarType(_avatarType.EPIC) }} oninfoClick={() => { showDesc() }} />
                                            </>
                                                :
                                                <>loading</>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="row btn_pad">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                    <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_back" label={WEB_STRINGS.CANCEL} onClick={() => { onCancel() }} />
                                    <Button type="button" className="btn btn-lg btn-md btn-sm btn-default btn_claim_continue" label={WEB_STRINGS.CONTINUE} onClick={() => { onNext() }} />
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order_total_price">
                                    <p className="p_pricing order_card_price" style={{ float: 'right' }}>Total : €{selectedAvatarType.price} </p>
                                </div>
                            </div>


                            {/*  <div className="row">
                                <div className="col-sm-12">
                                    <hr></hr>
                                    <p className="p_pricing" style={{ float: 'right' }}>Total : {selectedAvatarType.price} </p>
                                </div>
                            </div> */}
                        </div>
                        <div className={showDetailView ? "col-lg-4 col-md-4 image_section d-sm-block p-0" : "col-lg-4 col-md-4 image_section d-sm-block"}>
                            <div class="box">
                                <div class={showDetailView ? "hid-box_up" : "hid-box"}>
                                    <i className="pi pi-times" style={{ fontSize: '1.0rem', marginLeft: '20px', marginTop: '20px', padding: '8px' }} onClick={() => { setShowDetailView(false) }}></i>

                                    {selectedAvatarType.id &&
                                        <div className="row h-100 d-flex align-items-center justify-content-center p-3">
                                            <div className="card shadow-lg" style={{ overflow: 'scroll' }}>
                                                <div className="card-body">
                                                    <h3 className="main_titles">{language_code == 'en' ? CHOOSE_OPTION_DETAILS["RARE"].title : CHOOSE_OPTION_DETAILS["RARE"].title_pt}</h3>
                                                    <hr></hr>
                                                    <p className="payment_success_desc">{language_code == 'en' ? CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].desc : CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].desc_pt}</p>
                                                    <ul>
                                                        {renderBullets(language_code == 'en' ? CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].bullets : CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].bullets_pt)}
                                                    </ul>
                                                    <p className="confirm_policy">{language_code == 'en' ? CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].note : CHOOSE_OPTION_DETAILS[selectedAvatarType?.id].note_pt}</p>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}
export default OrderOptionContainer
import React from "react";
import { Tooltip } from 'primereact/tooltip';
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { LANGUAGE_CODE, LOGOUT, RENDER_URL } from '../common/Constants';;

function LocalizationHeader(props){
    
    const dispatch = React.useContext(GlobalDispatchContext)

    const onlanguageSelected = (code) => {
        if (code == 'EN') {
            dispatch({
                type: LANGUAGE_CODE,
                payload: 'en',
            });
        } else if (code == 'PT') {
            dispatch({
                type: LANGUAGE_CODE,
                payload: 'pt',
            });
        }
    }

    return(
        <>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>
                 <div style={{ float: 'right', cursor: 'pointer' }}>
                    <Tooltip/>
                    <span onClick={() => { onlanguageSelected('EN') }}><img src='https://benfica.interverse.ai/wp-content/uploads/2023/05/uk.png' style={{ width: '30px' }} /> <span style={{ marginRight: '10px',color:'white' }}>{/* <b>EN</b> */}</span></span>
                    <span onClick={() => { onlanguageSelected('PT') }}><img src='https://benfica.interverse.ai/wp-content/uploads/2023/05/portugal.png' style={{ width: '30px' }} /> <span style={{ marginRight: '10px',color:'white' }}>{/* <b>PT</b> */}</span></span>
                </div> 
            </div>
        </div>
        </>
    )
}
export default LocalizationHeader;
import React, { useState } from "react";
import TwitterLogin from 'react-twitter-auth';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import "./style.css";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { TWITTER_USER,LOCALISATION_ARRAY } from "../common/Constants";
import { twitterMapApi } from "./twitterService";
import LocalizedStrings from "react-localization";

function TwitterButtonComponent(props) {

    const authHandler = (err, data) => {
        console.log(err, data);
    };


    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    const [language_code, setLanguageCode] = useState(context.login.LANGUAGE_CODE)
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);




    const onSuccess = (response) => {

        const token = response.headers.get('x-auth-token');

        response.json().then(user => {
            if (token) {
                console.log("USER", user);
                twitterMapApi(JSON.stringify(user)).then((response) => {
                    if (response.data.success) {
                        setAuthenticated(true);
                        setUser(user);
                        setToken(token);
                        dispatch({
                            type: TWITTER_USER,
                            payload: { id: user.twitterProvider.id },
                        });
                        
                        window.location.reload();

                    }
                }).catch((error) => {
                    console.log("error");
                })
            }
        });
    };

    const onFailed = (error) => {
        alert(error);
    };

    const logout = () => {
        window.location.reload();
    };
    const [selected, setSelected] = useState(null);
    const list = [{ name: 'Items' },
    { name: 'Eagle' },];

    let content = !!isAuthenticated ?
        (
            <div>
                <Button label="Connected" icon='pi pi-twitter' className="social_button" onClick={() => { logout()() }} />
            </div>
        ) :
        (
            <TwitterLogin
                loginUrl="https://f8szkyr0zd.execute-api.eu-west-3.amazonaws.com/benefica/api/v1/auth/twitter"
                onFailure={(err) => { onFailed(err) }}
                onSuccess={(res) => { onSuccess(res) }}
                text= {WEB_STRINGS.CONNECT_TWITTER}
                style={{"backgroundColor":"white","border":"1px solid red","color":"red",fontFamily:'roboto'}}
                requestTokenUrl="https://f8szkyr0zd.execute-api.eu-west-3.amazonaws.com/benefica/api/v1/auth/twitter/reverse" />
        );



    return (
        <>
            {content}
        </>
    )
}
export default TwitterButtonComponent;
import React, { useEffect, useState } from "react";
import 'primeicons/primeicons.css';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { CHOOSE_OPTION_DETAILS, LOCALISATION_ARRAY } from "../common/Constants";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import LocalizedStrings from 'react-localization';

let claim_card_css = {
    selected: 'card shadow-lg rounded _card payment_border',
    not_selected: 'card shadow-lg rounded _card'
}
function OrderCard(props) {

    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)
    const [selectedMethod, setSelectedMethod] = useState(props.isSelected.name);
    const [id, setSelectedID] = useState(props.id.name);
    const [isSelected, setIsSelected] = useState(false);
    const [language_code, setLanguageCode] = useState(context.login.LANGUAGE_CODE)
    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    useEffect(() => {

        setLanguageCode(context.login.LANGUAGE_CODE);

    }, [context.login.LANGUAGE_CODE])

    useEffect(() => {
        if (props.isSelected.name == id) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [props.isSelected]);

    const renderBullets = (dataArray) => {
        return (
            Object.entries(dataArray).map(([index, object]) => {
                return <li className="payment_success_desc">{object}</li>
            }))
    }


    return (
        <>

            <div className="order_card  col-lg-4 col-md-4 col-sm-12 col-xs-12" onClick={() => { props.onclickfn() }}>
                <div className={isSelected ? claim_card_css.selected : claim_card_css.not_selected}>
                    <div>
                        {/* <i className="pi pi-info-circle" style={{ fontSize: '1.0rem', color: 'red', padding: '10px', float: 'right',cursor:'pointer' }} onClick={()=>{props.oninfoClick()}}></i>
                         */}
                         <span className="order_card_desc" onClick={()=>{props.oninfoClick()}} style={{padding: '10px', float: 'right',cursor:'pointer',border:'1px solid',margin:'10px'}}>{WEB_STRINGS.MOREINFO}</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center" style={{ padding: '10px' }}>
                            <img src={props.image} width="80%" className="img-responsive" />
                        </div>
                        <div className="col-sm-12" >
                            <div className="order_card_title">{props.title}</div>

                            <div className="order_card_desc">{props.description}</div>
                            <hr style={{ marginLeft: "15px", marginRight: "15px" }}></hr>
                            <div className="mobile_desc">
                                <Accordion className="p-1">
                                    <AccordionTab header={language_code == 'en' ? CHOOSE_OPTION_DETAILS["RARE"].title : CHOOSE_OPTION_DETAILS["RARE"].title_pt}>
                                        <div className="m-0">
                                            <p className="payment_success_desc">{language_code == 'en' ? CHOOSE_OPTION_DETAILS[props.id?.id].desc : CHOOSE_OPTION_DETAILS[props.id?.id].desc_pt}</p>
                                            <ul>
                                            {renderBullets(language_code == 'en' ? CHOOSE_OPTION_DETAILS[props.id?.id].bullets : CHOOSE_OPTION_DETAILS[props.id?.id].bullets_pt)}
                                            </ul>
                                            <p className="confirm_policy">{language_code == 'en' ? CHOOSE_OPTION_DETAILS[props.id?.id].note : CHOOSE_OPTION_DETAILS[props.id?.id].note_pt}</p>
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                                <hr></hr>
                            </div>

                            <p style={{ fontSize: '15px', color: 'black', fontWeight: 'bold', float: 'right', paddingRight: '10px' }}>€{props.price}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default OrderCard
import React, { Component, useState,useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LOGOUT, RENDER_URL } from './Constants';
import { ManageSessionStorage } from "../core/SessionStorage";

function UnLeadHeader(props) {
    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    return (
        <>
            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}
        </>
    )

}

export default UnLeadHeader;
import React from "react";

function MyAccountContainer(props) {
    return (
        <>
            <section className="section abstract_bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row" style={{margin:"10px"}}>
                                <div className="col-sm-10">
                                    <p className="order_card_desc" style={{float:"right",marginTop:"5px"}}>kevin@interverse.ai</p>
                                </div>
                                <div className="col-sm-2">
                                <input type="button" className="btn btn-danger form-control" value="Log Out" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-10">
                                    {/* <img src="./assets/images/logo.png"/> */}
                                </div>
                                <div className="col-sm-2 ">
                                    <img className="logo_mystery" src="./assets/images/logo.png" />
                                </div>
                            </div>
                            <div className="row">
                                <h2 className="h_deco"> MY EAGLE</h2>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 offset-2 mystery_img_div1">
                                    <div className="marquee">
                                        <div className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1583511666445-775f1f2116f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1586917383423-c25e88ac05ce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1560743173-567a3b5658b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1603232644140-bb47da511b92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ4MDE&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                        <div aria-hidden="true" className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1583511666445-775f1f2116f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1586917383423-c25e88ac05ce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1560743173-567a3b5658b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1603232644140-bb47da511b92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ4MDE&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="col-sm-12">
                                        <div className="card card_center" style={{ borderRadius: '10px' }}>
                                            <div className="row back_white">
                                                <div className="col-sm-2">
                                                    <img src="../assets/images/logo.png" width='50px' />
                                                </div>
                                                <div className="col-sm-10">
                                                    <h6>Vitory#3452</h6>
                                                </div>
                                            </div>
                                            <div className="row back_white attribute_title">
                                                <div className="col-sm-12">
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Attributes</p>
                                                </div>
                                            </div>
                                            <div className="row margin_test">
                                                <div className="col-sm-12">
                                                    <div style={{ paddingRight: '40px', position: 'relative' }}>
                                                        <div className='attributes'>
                                                            <div className="attribute" >
                                                                <span style={{ color: 'red' }}>Hat</span>
                                                                <span>Pirate Hat</span>
                                                                <span style={{ fontSize: '10px' }}>22% have this traits</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span>HEAD</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span>NOSE</span>
                                                            </div>
                                                        </div>
                                                        <div className='attributes'>
                                                            <div className="attribute" >
                                                                <span>Armband</span>
                                                                <span style={{ fontSize: '10px' }}>22% have this traits</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span style={{ color: 'red' }}>Clothing</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span style={{ color: "red" }}>Type</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 mystery_img_div2">
                                    <div className="marquee">
                                        <div className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1583511666445-775f1f2116f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1586917383423-c25e88ac05ce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1560743173-567a3b5658b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1603232644140-bb47da511b92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ4MDE&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                        <div aria-hidden="true" className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1583511666445-775f1f2116f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1586917383423-c25e88ac05ce?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1560743173-567a3b5658b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3NzU&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                            <img src="https://images.unsplash.com/photo-1603232644140-bb47da511b92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ4MDE&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <div className="btn_pad_generate_avatar" style={{ float: "right",marginTop:"10px",width:"100px" }}>
                                        <input type="button" className="btn form-control btn-danger" value="PREVIOUS" />
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="btn_pad_generate_avatar" style={{marginTop:"10px" }}>
                                        <input type="button" className="btn form-control btn-danger" value="BUY AGAIN" />
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="btn_pad_generate_avatar" style={{ float: "left",marginTop:"10px",width:"100px" }}>
                                        <input type="button" className="btn form-control btn-danger" value="NEXT" />
                                    </div>
                                </div>
                            </div>
                            <div className="row signin_foot">
                                <hr className="primary-color"></hr>
                                <p className="footer_text"> ©2023 interverse -SL Benefica Product All rights reserved</p>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}
export default MyAccountContainer;
import React, { useState, useEffect } from 'react';

import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM } from '../common/Constants';
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import { ManageSessionStorage } from '../core/SessionStorage';
import HeaderContainer from '../common/HeaderContainer';



function HomeContainer(props) {

    return (
        <>
            <main>
                <HeaderContainer></HeaderContainer>

                <section class="hero margin_nav" id="hero">
                    <div class="container">
                        <div class="row">
                            <div className='col-12 text-center'>
                                <h5 className='text-center mb-lg-5 mb-4'> OFFICIAL SL BENFICA DIGITAL AVATARS </h5>
                            </div>
                            <div class="col-sm-8 offset-2 text-center">
                                <video src='http://techslides.com/demos/sample-videos/small.mp4' controls="true" width="100%"></video>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12 text-center first_section_footer'>
                                <div className='row'>
                                    <div className='col-sm-8 offset-2 text-center'>
                                        <p className='text_white'>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
}

export default HomeContainer;
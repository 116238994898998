import { httpCall,httpFormCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"


export const dashboardAttributes = params => {
    return httpFormCall({
        url: API_URL.DASHBOARD_ATTRIBUTE,
        method: "GET",
        data: params
    }).then(response => {
        return response;
    });
};

export const retweetClaimCheck = params => {
    return httpCall({
        url: '/api/v1/twitter/retweeted/check',
        method: "POST",
        data: params
    }).then(response => {
        return response;
    });
};

export const likeClaimCHeck =params =>{
    return httpCall({
        url:'/api/v1/twitter/tweetlike/check',
        method:"POST",
        data: params
    }).then(response => {
        return response;
    });
};


export const getLatestPinnedTweet =params =>{
    return httpCall({
        url:'/api/v1/twitter/pinnedtweet',
        method:"POST",
        data: params
    }).then(response => {
        return response;
    });
};






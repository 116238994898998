import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { RENDER_URL,LOCALISATION_ARRAY } from "../common/Constants";
import './AssemblyCSS.css'
import LocalizedStrings from "react-localization";


function AssemblyContainer(props) {


    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    useEffect(() => {

   

    }, []);


    const onCreateButtonClick = () => {
        _history(`${RENDER_URL.SIGNIN_URL}`);
    }

    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">

                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                <div class="pl">
                                    <svg class="pl__rings" viewBox="0 0 128 128" width="128px" height="128px">
                                        <g fill="none" stroke-linecap="round" stroke-width="4">
                                            <g class="pl__ring" transform="rotate(0)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(0)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(0)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(0)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(180)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(180)">
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(0)">
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                            </g>
                                            <g class="pl__ring" transform="rotate(180)">
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                            </g>
                                        </g>
                                    </svg>
                                    <div class="pl__nucleus">
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                        <div class="pl__nucleus-particle"></div>
                                    </div>
                                </div>
                                <p className="text-center privacy_policy">{WEB_STRINGS.ASSEMBLE}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


export default AssemblyContainer;
import React, { useState } from "react";
import TwitterLogin from 'react-twitter-auth';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import "./style.css";
import LogoHeader from "../common/LogoHeader";
function TwitterContainer() {
    const authHandler = (err, data) => {
        console.log(err, data);
    };

    const [isAuthenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');



    const onSuccess = (response) => {
        
        console.log("res",response.headers);

        const token = response.headers.forEach(e=>{
            console.log(e);
        });

        for (var pair of response.headers.entries()) { // accessing the entries
            console.log("looping ",pair[0]);
          }

        console.log(">>",response.headers.get('X-Auth-Token'));
        console.log(">>",response.headers.get('x-auth-token'));
        console.log("..",token);
        response.json().then(user => {
            if (token) {
                setAuthenticated(true);
                setUser(user);
                setToken(token);
            }
        });
    };

    const onFailed = (error) => {
        alert(error);
    };

    const logout = () => {
        setAuthenticated(false);
        setToken('');
        setUser(null);
    };
    const [selected, setSelected] = useState(null);
    const list = [{ name: 'Items' },
    { name: 'Eagle' },];

    let content = !!isAuthenticated ?
        (
            <div>
                <p>Authenticated</p>
                <div>
                    {user?.email}
                </div>
                <div>
                    <button onClick={() => { logout() }} className="button" >
                        Log out
                    </button>
                </div>
            </div>
        ) :
        (
            <TwitterLogin loginUrl="http://localhost:3001/blocal/api/v1/auth/twitter"
                onFailure={(err) => { onFailed(err) }} onSuccess={(res) => { onSuccess(res) }}
                requestTokenUrl="http://localhost:3001/blocal/api/v1/auth/twitter/reverse" />
        );



    return (
        <>
            <section className="section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 header_section">
                            <div className="row header_padding">
                                <div className="col-sm-4">
                                    <p className="text_white">PARADISE BRAWL<br></br>
                                        <b>BENFICA ISLAND</b>
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                   
                                </div>
                                <div className="col-sm-2">
                                    <div className="card flex justify-content-center">
                                        <Dropdown value={selected} onChange={(e) => setSelected(e.value)} options={list} optionLabel="name" placeholder={<i className="pi pi-home" style={{ color: 'red', fontSize: '1rem' }}><b> DASHBOARD</b></i>} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-2">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <img className="img_section" src="../assets/images/ScrollGroup83.png"></img>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="row title_padding">
                                            <div className="col-sm-10">
                                                <p>SEASON 01</p>
                                                <p>BVE #2456</p>
                                            </div>
                                            <div className="col-sm-2">
                                                <img className="title_logo" src="../assets/images/logo.png" />
                                            </div>
                                        </div>
                                        <TabView>
                                            <TabPanel header="Task (3)">
                                                <p className="m-0">
                                                    <table>
                                                        <tr>
                                                            <div className="row">
                                                                <div className="col-sm-1">
                                                                    <i className="pi pi-twitter icon_decoration"></i>
                                                                </div>
                                                                <div className="col-sm-9">
                                                                    <h5 style={{ color: 'red' }}>Twitter PFP</h5>
                                                                    <p>
                                                                        Earn 5 coins per day for having your Victory Eagle as your Twitter Profile Picture(Every 24 hours)
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p style={{ color: 'red' }}><b>5 coins</b></p>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        <tr>
                                                            <div className="row">
                                                                <div className="col-sm-1">
                                                                    <i className="pi pi-twitter icon_decoration"></i>
                                                                </div>
                                                                <div className="col-sm-9">
                                                                    <h5 style={{ color: 'red' }}>Twitter Retweets</h5>
                                                                    <p>
                                                                        Earn 10 coins for retweeting a recent tweet from the VictorySLB Twitter account(Every 24 hours)
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p style={{ color: 'red' }}><b>10 coins</b></p>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        <tr>
                                                            <div className="row">
                                                                <div className="col-sm-1">
                                                                    <i className="pi pi-twitter icon_decoration"></i>
                                                                </div>
                                                                <div className="col-sm-9">
                                                                    <h5 style={{ color: 'red' }}>Twitter Likes</h5>
                                                                    <p>
                                                                        Earn 10 coins for linking a recent tweet from the VictorySLB Twitter Account
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p style={{ color: 'red' }}><b>10 coins</b></p>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                    </table>
                                                </p>
                                            </TabPanel>
                                            <TabPanel header="Completed (0)">
                                            <table>
                                                        <tr>
                                                            <div className="row">
                                                                <div className="col-sm-1">
                                                                    <i className="pi pi-twitter icon_decoration"></i>
                                                                </div>
                                                                <div className="col-sm-9">
                                                                    <h5 style={{ color: 'red' }}>Twitter PFP</h5>
                                                                    <p>
                                                                        Earn 5 coins per day for having your Victory Eagle as your Twitter Profile Picture(Every 24 hours)
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p style={{ color: 'red' }}><b>5 coins</b></p>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        
                                                    </table>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </div>
                                <div className="row">
                                    <h5 style={{ color: 'red', textAlign: 'right' }}>Coins earned:</h5>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h4 className="title_my_avtr"><img className="title_logo" src="../assets/images/logo.png" />MY AVATARS</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 div_col">
                                        <img className="justify-content-center" style={{ width: '100%', marginTop: '10px' }} src="../assets/images/common_avatar.png" alt="image" />
                                    </div>
                                    <div className="col-sm-4 div_col1 ">
                                        <div className="btn_padding">
                                            <p className="order_now_title">Get another Avatar</p>
                                            <button type="button" className="btn btn-danger btn_order_now">Order now</button>
                                        </div>
                                    </div>
                                </div>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
export default TwitterContainer;
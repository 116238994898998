import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./Stripe.css";
import LevelContainer from "../Level/LevelContainer";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { Toast } from 'primereact/toast';
import { START_LOADING, STOP_LOADING, LOCALISATION_ARRAY } from "../common/Constants";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import LocalizedStrings from "react-localization";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
//const stripePromise = loadStripe("pk_test_a0th32BS4FuqcFNwAFfPmT7i00c6OgVPQ7");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export default function StripeContainer() {
  const toast = useRef(null);
  const [clientSecret, setClientSecret] = useState("");

  const context = React.useContext(GlobalContext);
  const dispatch = React.useContext(GlobalDispatchContext)

  let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
  WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);


  useEffect(() => {
 
      dispatch({
        type: START_LOADING,
      });

      let selectedOrderOption = context.login.setSelectedOrderOption;
      let selectedChoiceOption = context.login.setSelectedBoxOption;
      let coupon = context.login.COUPON_CODE;

      let data = {}

      if(coupon){
        data = { productType: `${selectedOrderOption.id}`, choiceType: `${selectedChoiceOption.id}`,coupon: `${coupon}` }
      }
      else{
        data = { productType: `${selectedOrderOption.id}`, choiceType: `${selectedChoiceOption.id}` }
      }
      // Create PaymentIntent as soon as the page loads
      fetch("https://f8szkyr0zd.execute-api.eu-west-3.amazonaws.com/benefica/payment/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          dispatch({
            type: STOP_LOADING,
          });
          return res.json()
        })
        .then((data) => {
          dispatch({
            type: STOP_LOADING,
          });
          setClientSecret(data.clientSecret)
        });

  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const showSuccess = (severity, summary, messageTo) => {
    toast.current.show({ severity: `${severity}`, summary: `${summary}`, detail: `${messageTo}`, life: 3000 });
  }

  return (

    <>
      <section className="section">
        <div className="container-fluid">
          <UnLeadHeader></UnLeadHeader>
          <Toast ref={toast} />
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 main_section">
              <LogoHeader></LogoHeader>
              {/* <div className="row" style={{ marginTop: '50px' }}>
                <div className='col-sm-12'>
                  <LevelContainer levelCount={5} completed={3}></LevelContainer>
                </div>
              </div> */}

              <div className="row">
                <h4 className="main_titles">{WEB_STRINGS.PAYMENT_TITLE}</h4>
              </div>

              <div className="row">
                <div className="App">
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm onSuccess={(severity, summary, messageTo) => { showSuccess(severity, summary, messageTo) }} />
                    </Elements>
                  )}
                </div>
              </div>



              {/* <div className="row">
                        <div className="col-sm-12">
                            <hr></hr>
                            <p style={{ float: 'right' }}>Total : $4.99 </p>
                        </div>
                    </div> */}

              {/*   <div className="row signin_foot">
                <hr className="primary-color"></hr>
                <p className="footer_text"> ©2023 interverse -SL Benefica Product All rights reserved</p>
              </div> */}

            </div>
            <div className="col-sm-4 image_payment">
            </div>
          </div>
        </div>


      </section>
    </>



  );
}

export const BUILD_VERSION = "Build_Version";
export const TWITTER_USER = "TWITTER_USER";
export const FROM_DASHBOARD = "FROM_DASHBOARD";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_SELECTED_ORDER_OPTION = "SET_SELECTED_ORDER_OPTION";
export const SET_SELECTED_BOX_OPTION = "SET_SELECTED_BOX_OPTION";
export const PAYMENT_UUID = "P_UUID";
export const AVATAR_TYPES = "AVATAR_TYPES";
export const AVATAR_CHOICE = "AVATAR_CHOICE";
export const LANGUAGE_CODE = "LANGUAGE_CODE";
export const COUPON_CODE = "COUPON_CODE";
export const SET_RUMBLE_LOCAL = "SET_RUMBLE_LOCAL";
export const SET_ASSET_LOCAL = "SET_ASSETS_LOCAL";
export const SET_COLLECTION = "SET_ACADEMY_COLLECTION"
export const SET_ASSET = "SET_ASSET_COLLECTION"
export const SET_ITEM = "SET_ITEM_COLLECTION"
export const USER_DATA = "User_Data"
export const USER_TOKEN = "User_Token"
export const APP_TOKEN = "Application_Token"
export const ACDMY_COLLECTION = "academyCollectionList";
export const ASSET_COLLECTION = "academyAssetList";
export const ITEM_COLLECTION = "academyItemList";
export const STATUS_CODES = {
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_409: 409,
    HTTP_422: 422,
    HTTP_500: 500,
    HTTP_501: 501,
}


/**
 * API and Render url defined
 */
export const RENDER_URL = {
    LOGIN_URL: '/',
    LOGIN_NEW_URL: '/login',
    HOME_URL: '/home',
    SIGNIN_URL: '/signin',
    MYSTERY_BOX_URL: '/mbox',
    SPIN_BOX_URL: '/spin',
    CLAIM_URL: '/claim',
    ORDER_OPT_URL: '/order_optn',
    PAYMENT_URL: '/payment',
    STRIPE_PAY_URL: '/stripe_pay',
    THANKYOU_URL: '/thankyou',
    MYACCOUNT_URL: '/myaccount',
    PAYMENT_SUCCESS: '/success',
    PAYMENT_DECLINED: '/failed',
    CONFIRM_AVATAR: '/confirm',
    ASSEMBLE_CONTAINER: '/assembly',
    FORGOT_CONTAINER: '/forgot',
    RESET_CONTAINER: '/change',
    STRIPE_USER: '/mbway',
    DASHBOARD: '/dashboard',
    TWITTER:'/twitter',
};


export const API_URL = {
    COUPON_VALIDATE: "/payment/coupon/validate",
    MBWAY_CONFIRM: "/payment/confirmation",
    MBWAY_STATUSCHECK: "/payment/status",
    MBWAY_INITIATE: "/payment/create-payment",
    PAYMENT_UPDATE: "/payment/update",
    SIGN_UP_API: "/accounts/user/register",
    LOGIN_API: "/accounts/user/login",
    FORGOT_API: "/accounts/user/forgotPassword",
    CHOOSE_AVATAR: "/avatars/choose",
    CLAIM_AVATAR: "/avatars/claim",
    PRICING_DETAILS: "/payment/prices",
    RESET_API: "/accounts/user/resetPassword",
    DASHBOARD_ATTRIBUTE: "/accounts/user/attributes",
    RE_TWEET_CLAIM:"/blocal/api/v1/twitter/retweeted/check",
}


/**
 * 
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


function getColors(num) {
    const initialColor = Math.floor(Math.random() * 360);
    const increment = 360 / num;
    const hsls = [];
    for (let i = 0; i < num; i++) {
        hsls.push(Math.round((initialColor + (i * increment)) % 360));
    }
    return hsls;
}
export function generateRandomColors(_length) {
    const hsls = getColors(_length);
    let shuffled_hsls = hsls
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    return shuffled_hsls;

}

export const PAYMENT_TYPE = {
    VISA: "visa",
    MB_WAY: "mb_way"
}
/* export const AVATAR_TYPE={
    COMMON:{
        id:'COMMON',
        name:'COMMON',
        description:'UNLIMITED AVAILABILITY',
        price:'29.99',
        image:'../assets/images/common_avatar.png'
    },
    RARE:{
        id:'RARE',
        name:'RARE',
        description:'ONLY 8000 AVAILABLE',
        price:'49.99',
        image:'../assets/images/rare_avatar.png'
    },
    EPIC:{
        id:'EPIC',
        name:'EPIC',
        description:'RARE ONES',
        price:'79.99',
        image:'../assets/images/epic_avatar.png'
    }
} */
export const SELECTED_AVATAR = {
    FIRST: "first",
    SECOND: "second",
    THIRD: "third",
    FOURTH: "fourth"
}
/* 
export const CHOOSE_OPTION ={
    MYSTERY_BOX:{
        id:'MYSTERY',
        name:'MYSTERY BOX',
        price:'0.00',
        image:'../assets/images/mystery.png',
        description:'WE WILL RANDOMLY ASSIGN YOU AN EAGLE FROM THE COLLECTIONS'
    },
    SPIN_CHOOSE:{
        id:'SPIN',
        name:'SPIN & CHOOSE',
        price:'5.00',
        image:'../assets/images/lottery.png',
        description:'YOU WILL BE ABLE TO SELECT YOUR FAVOURITE AVATAR OUT OF FIVE CHOICE'
    }
}

 */


export const LOCALISATION_ARRAY = {
    en: {
        MOREINFO:'More info',
        LOGIN: 'LOGIN',
        NAME: 'Name',
        ORDER_TITLE: "Select your Avatar rarity",
        CLAIM_TITLE: "Select your Victory Eagle Creation option",
        CONTINUE: 'CONTINUE',
        CANCEL: 'CANCEL',
        MYSTERY_BOX: 'MYSTERY BOX',
        SPIN_CHOOSE: 'SPIN & CHOOSE',
        BACK: 'BACK',
        PAYMENT_TITLE: 'Please fill in your payment details',
        CARD_TYPE: 'Credit / Debit card',
        COMMON: 'Common',
        CHOICE: 'Choice',
        CARD_NUMBER: 'Card number',
        EXPIRY: 'Expiration',
        COUNTRY: 'Country',
        CREATE_ACCOUNT: 'CREATE ACCOUNT',
        FOOTER: 'Interverse - SL Benfica licensed product. All rights reserved',
        PAYMENT_FORM_FOOTER: 'All payments are securely processed by Stripe, a PCI Service Provider with the highest level of certification available in the payments industry',
        PAYMENT_SUCCESS_TITLE: 'Thank you for purchasing, your payment was successful.',
        PAYMENT_SUCCESS_DESC: 'In order to claim your Victory Eagle Avatar, you will need to create an official account. Please click on the button below to get started.',
        FOOTER_SUCCES: 'By creating an account your agree to our',
        PRIVACY_POLICY: 'privacy policy',
        PAYMENT_FAILED_TITLE: 'Sorry, your payment was not processed.',
        PAYMENT_FAILED_DESC: 'It seems something went wrong during your payment processing. Please return to the main screen and try again.',
        RETURN_TO_MAINSCREEN: 'Return to main screen',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
        CLICK_HERE_TO_LOGIN: 'Click here to login',
        WELCOME_BACK: 'Welcome back!',
        FORGOT_PASSWORD: 'Forgot Password',
        RESET_PASSWORD_BTN: 'Reset Password',
        DONT_HAVE_AN_ACCOUNT: "Don't have an account yet ?",
        CLICK_HERE_TO_REGISTER: 'Sign up',
        CONFIRM_TITLE: 'Are you ready to create your Victory Eagle Avatar?',
        CONFIRM_DESC_2: 'Congratulations, you are about to create your very own Victory Eagle Avatar.You selected the Mystery Box, which means that we will randomly choose one of the available avatars from the entire digital collection. Once a Victory Eagle Avatar is assigned to you, it is yours, and there will be not a single one like it!',
        CONFIRM_DESC_1: 'Congratulations, you are about to create your very own Victory Eagle Avatar.You decided to Spin & Choose, which means that you will get to select one Victory Eagle Avatar from six available options. Create the first Avatar by clicking the button below. After that, click "Spin Again" to create five more Victory Eagle Avatars to choose from.',
        CREATE_MY_AVATAR: 'CREATE MY AVATAR',
        DISCLAIMER: "Disclaimer: please keep in mind that that your assigned Victory Eagle Avatar can't be swapped or traded to other Avatars until the marketplace is launched later this year. All Victory Eagle Avatars are DIGITAL meaning we do not provide a physical item of the digital Avatar.",
        ASSEMBLE: 'Assembling all available Victory Eagle Avatars, please wait...',
        CONGRATULATIONS: 'CONGRATULATIONS',
        MYSTERY_BOX_SUCCESS: 'You have successfully created your Victory Eagle Avatar',
        DOWNLOAD_IMAGES: 'Download Image',
        FOLLOW_THESE_STEPS: 'Follow these next steps',
        STEP_1: 'Download the Victory Eagle images and update your social media picture.',
        STEP_2: 'Follow us on Instagram & Twitter for updates and news',
        STEP_3: 'Check your inbox for your confirmation email',
        FOLLOW_US: 'Follow',
        ORDER_AGAIN: 'Order Again',
        ORDER_NOW: 'Order Now',
        SPIN_AND_CHOOSE: 'SPIN & CHOOSE',
        SPIN_AND_CHOOSE_DESC: 'After spinning through all six Victory Eagle Avatars, select your favorite one to continue.',
        CONTINUE_WITH_SELECTED_AVATAR: 'CONTINUE WITH SELECTED AVATAR',
        SPIN_TO_NEXT_AVATAR: 'SPIN TO NEXT AVATAR',
        ERROR: 'Error',
        ENTER_VALID_EMAIL: 'Enter valid email',
        PASSWORD_VALIDATION: 'Password must be 8 letters or more',
        SUCCESS: 'Success',
        PAYMENT_SUCCESSFUL: 'Payment Successful',
        ENTER_VALID_NAME: 'Enter valid name',
        PASSWORD_LENGTH_VALIDATION: 'Password must be 8 letters or more',
        EMAIL_PLACEHOLDER: 'name@example.com',
        RESET_TITLE: 'RESET PASSWORD',
        ERROR_IN_LOGIN: 'Error in login',
        MOBILE_NUMBER: 'Mobile Number',
        SUBMIT: 'SUBMIT',
        RESET_PASSWORD_MESSAGE: 'An email with password reset link has been sent to',
        VALID_COUPON: 'Your coupon was successfully applied',
        NOTVALID_COUPON: 'The coupon code you entered is invalid',
        APPLY_COUPON_CODE:'Apply Coupon Code',
        APPLY:'APPLY',
        DISCOUNT:'Discount',
        ENTER_VALID_COUPON:'Enter valid coupon',
        SEASON:'Season',
        DASHBOARD:'DASHBOARD',
        LOG_OUT:'LOG OUT',
        TASK:'Quests',
        COMPLETED:'Completed',
        TWITTER_TASK_1:'Earn 5 coins per day for having your Victory Eagle as your Twitter Profile Picture (Every 24 hours)',
        TWITTER_TASK_2:'Earn 10 coins for retweeting a recent tweet from the VictorySLB Twitter account (Every 24 hours)',
        TWITTER_TASK_3:'Earn 10 coins for linking a recent tweet from the VictorySLB Twitter Account',
        COINS_EARNED:'Coins earned',
        MY_AVATARS:'MY AVATARS',
        GET_ANOTHER_AVATAR:'Get another avatar',
        TWITTER_RETWEETS:'Twitter Retweets',
        COINS:'coins',
        CLAIM:'CLAIM',
        LIKE_THIS_TWEET:'Like this tweet',
        LIKE_THIS_TWEET_TASK:'Like the tweets on our twitter page VICTORY SLB',
        CONNECT_TWITTER:'Connect',
        CONNECT_TWITTER_TASK:'Earn 20 coins by connecting twitter accounts',
        TWITTER_CONNECTED:'Connected',
        CONNECTED:'Connected',
        CHALLENGE:'Challenge not completed',
    },
    pt: {
        MOREINFO:'Mais informações',
        LOGIN: 'CONECTE-SE',
        NAME: 'Nome',
        ORDER_TITLE: "Selecione sua raridade de avatar",
        CLAIM_TITLE: "Seleciona o modo de criação da tua Águia Victory ",
        CONTINUE: 'CONTINUAR',
        CANCEL: 'CANCELAR',
        MYSTERY_BOX: 'CAIXA MISTÉRIO',
        SPIN_CHOOSE: 'RODA & ESCOLHE',
        BACK: 'VOLTAR',
        PAYMENT_TITLE: 'Por favor preenche os detalhes de pagamento',
        CARD_TYPE: 'Cartão de Crédito / Débito',
        COMMON: 'Comum',
        CHOICE: 'Escolha',
        CARD_NUMBER: 'Número do Cartão',
        EXPIRY: 'Data de Validade',
        COUNTRY: 'País',
        CREATE_ACCOUNT: 'CRIAR CONTA',
        FOOTER: 'Interverse - SL Benfica produto licensiado. Todos os direitos reservados',
        PAYMENT_FORM_FOOTER: 'Todos os pagamentos são processados de forma segura pela Stripe, um Provedor de Serviços PCI com o mais alto nível de certificação disponível na indústria de pagamentos',
        PAYMENT_SUCCESS_TITLE: 'Obrigado pela compra, o teu pagamento foi realizado com sucesso',
        PAYMENT_SUCCESS_DESC: 'Para reivindicares o teu Avatar da Águia Victory, será necessário criar uma conta oficial. Por favor, clica no botão abaixo para começar',
        FOOTER_SUCCES: 'Ao criares uma conta, concordas com a nossa',
        PRIVACY_POLICY: 'política de privacidade.',
        PAYMENT_FAILED_TITLE: 'Desculpa, o teu pagamento não foi processado.',
        PAYMENT_FAILED_DESC: 'Parece que ocorreu um problema durante o processo de pagamento.',
        RETURN_TO_MAINSCREEN: 'Voltar ao menu principal',
        ALREADY_HAVE_AN_ACCOUNT: 'Já tens uma conta?',
        CLICK_HERE_TO_LOGIN: 'Clica aqui para fazer login',
        WELCOME_BACK: 'Bem-vindo de volta!',
        DONT_HAVE_AN_ACCOUNT: 'Não tens uma conta ainda?',
        CLICK_HERE_TO_REGISTER: 'inscrever-se',
        FORGOT_PASSWORD: 'Esqueceu sua senha',
        RESET_PASSWORD_BTN: 'Esqueceu sua senha',
        CONFIRM_TITLE: 'Estás pronto para criar o teu avatar da águia Victory?',
        CONFIRM_DESC_1: 'Parabéns, estás prestes a criar o teu próprio Avatar da Águia Victory Decidiste o Roda e Escolhe, o que significa que poderás selecionar um Avatar entre seis opções disponíveis. Cria o primeiro Avatar clicando no botão abaixo. Depois disso, clica em "Girar Novamente" para criares mais cinco Avatares da Águia Victory para escolheres.',
        CONFIRM_DESC_2: 'Parabéns, estás prestes a criar o teu próprio Avatar da Águia Victory. Selecionaste a Caixa Mistério, o que significa que iremos escolher aleatoriamente um dos avatares disponíveis de toda a coleção digital. Uma vez que o avatar te seja atribuído, ele será exclusivamente teu, e não haverá nenhum outro igual!',
        CREATE_MY_AVATAR: 'CRIAR O MEU AVATAR',
        DISCLAIMER: 'Aviso: por favor, tem em mente que o teu Avatar da àguia Victory atribuído não pode ser trocado ou negociado por outros Avatares até o lançamento do marketplace mais tarde este ano. Todos os avatares são "digitais", o que significa que não fornecemos um item físico do Avatar digital.',
        ASSEMBLE: 'A reunir todos os avatares da Águia Victory, por favor espera...',
        CONGRATULATIONS: 'PARABÉNS',
        MYSTERY_BOX_SUCCESS: 'Criaste com sucesso o teu avatar da Águia Victory',
        DOWNLOAD_IMAGES: 'Download de Imagen',
        FOLLOW_THESE_STEPS: 'Segue estes próximos passos',
        STEP_1: 'Faz o download das imagens da Águia Victory e actualiza a tua imagem das redes sociais',
        STEP_2: 'Segue-nos no Instragram & Twitter para notícias e actualizações.',
        STEP_3: 'Verifica a tua caixa de entrada para o teu email de confirmação.',
        FOLLOW_US: 'Segue-nos',
        ORDER_AGAIN: 'Nova Compra',
        ORDER_NOW: 'Peça agora',
        SPIN_AND_CHOOSE: 'RODA & ESCOLHE',
        SPIN_AND_CHOOSE_DESC: 'Depois de rodares pelos seis avatares da Águia Victory, seleciona o teu favorito.',
        CONTINUE_WITH_SELECTED_AVATAR: 'CONTINUA COM O AVATAR SELECIONADO',
        SPIN_TO_NEXT_AVATAR: 'RODA PARA O PRÓXIMO AVATAR',
        ERROR: 'Erro',
        ENTER_VALID_EMAIL: 'Digite um e-mail válido',
        PASSWORD_VALIDATION: 'A senha deve ter 8 letras ou mais',
        SUCCESS: 'Sucesso',
        PAYMENT_SUCCESSFUL: 'Pagamento bem sucedido',
        ENTER_VALID_NAME: 'Insira um nome válido',
        PASSWORD_LENGTH_VALIDATION: 'A senha deve ter 8 letras ou mais',
        EMAIL_PLACEHOLDER: 'nome@exemplo.com',
        RESET_TITLE: 'redefinir senha',
        ERROR_IN_LOGIN: 'Erro no login',
        MOBILE_NUMBER: 'número de telemóvel',
        SUBMIT: 'ENVIAR',
        RESET_PASSWORD_MESSAGE: 'Um e-mail com link de redefinição de senha foi enviado para',
        VALID_COUPON: 'Seu cupom foi aplicado com sucesso',
        NOTVALID_COUPON: 'O código do cupom inserido é inválido',
        APPLY_COUPON_CODE:'Aplicar código de cupom',
        APPLY:'APLICAR',
        DISCOUNT:'Desconto',
        ENTER_VALID_COUPON:'Insira um cupom válido',
        SEASON:'Temporada',
        DASHBOARD:'MENU',
        LOG_OUT:'SAIR',
        TASK:'Missões',
        COMPLETED:'Completas',
        TWITTER_TASK_1:'Ganha 5 moedas por dia ao teres o teu avatar da Victory Eagle como imagem de perfil no Twitter (Cada 24 Horas)',
        TWITTER_TASK_2:'Ganha 10 moedas por ao fazeres um retweet da conta da VictorySLB no Twitter (Cada 24 Horas).',
        TWITTER_TASK_3:'Ganha 10 moedas ao dares um like num post recente da conta Victory SLB no Twitter.',
        COINS_EARNED:'Moedas Ganhas',
        MY_AVATARS:'OS MEUS AVATARES',
        GET_ANOTHER_AVATAR:'Obtém outro avatar',
        TWITTER_RETWEETS:'Retuítes do Twitter',
        COINS:'moedas',
        CLAIM:'ALEGAR',
        LIKE_THIS_TWEET:'Curta este tweet',
        LIKE_THIS_TWEET_TASK:'Curta os tweets em nossa página no Twitter VICTORY SLB',
        CONNECT_TWITTER:'Conectar',
        CONNECT_TWITTER_TASK:'Ganha 20 moedas conectando contas do Twitter',
        TWITTER_CONNECTED:'Conectado',
        CONNECTED:'Conectado',
        CHALLENGE:'Desafio não concluído'


    }
}


export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const MBWAY_PAYMENTSTATUS = {
    UNKNOWN: "UNKNOWN",
    WAITING: "WAITING",
    DONE: "DONE",
    FAILED: "FAILED"
}


export const CHOOSE_OPTION_DETAILS = {
    RARE: {
        title:'Description',
        title_pt:'Descricao',
        desc_pt: 'Este Avatar impressionante é uma verdadeira obra de arte rara trazida à vida em 8000 versões únicas para os verdadeiros adeptos do Benfica. A tua compra é um avatar digital de corpo inteiro numa caixa digital oficial de colecionador, que inclui:',
        desc: 'This awe-inspiring Avatar is a true work of rare art brought to life in 8000 unique versions for the truest Benfica supporters. Your purchase is a digital full body avatar in an official digital collectible box, including:',
        bullets: [
            'High Profile Picture for Social Media',
            '20 Digital Benfica Victory Eagle Stickers',
            'Augmented Reality Selfie- Filter',
            'Exclusive access to the Benfica Victory Eagle Community Events',
            'Free additional rare digital clothing & accessories',
            'Chance to win Benfica merchandise & game tickets'
        ],
        bullets_pt: [
            'Imagem de perfil em alta resolução para as tuas redes sociais',
            '20 stickers digitais da Águia Victory do Benfica',
            'Filtro de selfie com Realidade Aumentada',
            'Acesso exclusivo aos Eventos da Comunidade da Águia Victory do Benfica',
            'Roupas e acessórios digitais adicionais raros gratuitos',
            'Chances de ganhares produtos oficiais do Benfica e bilhetes para jogos'
        ],
        note: 'Please note that the marketplace, mobile application and Benfica Island game are launched very soon and you will be the first to access these amazing experiences.',
        note_pt: 'Tem em mente que o mercado, a aplicação para telemóvel e o jogo Benfica Island serão lançados em breve e tu serás o primeiro a ter acesso a estas experiências incríveis.'

    },
    COMMON: {
        desc: 'This majestic Avatar is a symbol of strength and freedom that comes in three styles: the home, away or third shirt of Benfica. Your purchase is a digital full body avatar in an official digital collectible box, including:',
        desc_pt: 'Este imponente Avatar é um símbolo de força e liberdade, apresentado em três variantes: a camisola de casa, de fora ou a terceira do Benfica. A tua compra é um avatar digital de corpo inteiro numa caixa digital oficial de colecionador, que inclui:',
        bullets: [
            'High Profile Picture for Social Media',
            '15 Digital Benfica Victory Eagle Stickers',
            'Augmented Reality Selfie-Filter',
            'Exclusive access to the Benfica Victory Eagle Community Events',
            'Free digital Jersey upgrade for 23/24',
            'Chance to win Benfica merchandise & game tickets'
        ],
        bullets_pt: [
            'Imagem de perfil em alta resolução para as tuas redes sociais',
            '15 stickers digitais da Águia Victory do Benfica',
            'Filtro de selfie com Realidade Aumentada',
            'Acesso exclusivo aos Eventos da Comunidade da Águia Victory do Benfica',
            'Atualização gratuita da camisola digital para a época 23/24',
            'Chances de ganhares produtos oficiais do Benfica e bilhetes para jogos'
        ],
        note: 'Please note that the marketplace, mobile application and Benfica Island game are launched very soon and you will be the first to access these amazing experiences.',
        note_pt: 'Tem em mente que o mercado, a aplicação para telemóvel e o jogo Benfica Island serão lançados em breve e tu serás o primeiro a ter acesso a estas experiências incríveis.'
    },
    EPIC: {
        desc: 'With only 2000 Epic Avatars in existence, it is like possessing a piece of digital gold made for the most elite Benfica fans. Your purchase is a digital full body avatar in an official digital collectible box, including:',
        desc_pt: 'Com apenas 2000 Avatares Épicos a existirem, é como teres uma peça de ouro digital feita para os adeptos mais elite do Benfica. A tua compra é um avatar digital de corpo inteiro numa caixa digital oficial de colecionador, que inclui:',
        bullets: [
            'High Profile Picture for Social Media',
            '25 Digital Benfica Victory Eagle Stickers',
            'Augmented Reality Selfie-Filter',
            'Exclusive access to the Benfica Victory Eagle Community Events',
            'Free additional rare digital clothing & accessories',
            'Chance to win Benfica merchandise & game tickets'

        ],
        bullets_pt: [
            'Imagem de perfil em alta resolução para as tuas redes sociais',
            '25 stickers digitais da Águia Victory do Benfica',
            'Filtro de selfie com Realidade Aumentada',
            'Acesso exclusivo aos Eventos da Comunidade da Águia Victory do Benfica',
            'Roupas e acessórios digitais adicionais raros gratuitos',
            'Chances de ganhares produtos oficiais do Benfica e bilhetes para jogos'
        ],
        note: 'Please note that the marketplace, mobile application and Benfica Island game are launched very soon and you will be the first to access these amazing experiences',
        note_pt: 'Tem em mente que o mercado, a aplicação para telemóvel e o jogo Benfica Island serão lançados em breve e tu serás o primeiro a ter acesso a estas experiências incríveis.'
    }
}


import {
    START_LOADING,
    STOP_LOADING,
    SET_RUMBLE_LOCAL,
    SET_COLLECTION,
    SET_ASSET_LOCAL,
    SET_SELECTED_ORDER_OPTION
} from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage"
import { ManageSessionStorage } from "../core/SessionStorage";

export const commonInitialState = {
    isLoading: false,
    isGuestAdd: false

};

export const commonReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return commonInitialState
    }
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
};
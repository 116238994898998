import React, { useEffect, useState } from "react";

let payment_css = {
    selected: 'card shadow-lg card_payment payment_border',
    not_selected: 'card shadow-lg card_payment'
}

function PaymentCard(props) {
    const [selectedMethod, setSelectedMethod] = useState(props.isSelected);
    const [id, setSelectedID] = useState(props.id);

    const [isSelected, setIsSelected] = useState(false);


    useEffect(() => {
        if (props.isSelected == id) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [props.isSelected]);


    return (
        <>
            <div className='col-lg-6 col-md-12 col-sm-12' onClick={() => { props.onclickfn() }}>
                <div className={isSelected ? payment_css.selected : payment_css.not_selected}  >
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 text-center" style={{ padding: '10px',marginTop:'10%' }}>
                                <img style={{maxHeight:'50px'}} className="img-responsive" src={props.img_url} />
                            <hr style={{ marginLeft: "25px", marginRight: "25px" }}></hr>
                            <p className="payment_name text-center" style={{ padding: '10px',fontWeight:'700',fontSize:'15px' }}>{props.card}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default PaymentCard
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { history } from "./core/store"
import { httpInterceptor, setBaseUrl, setApplicationToken } from "./core/HttpService"
import _Routes from './core/Routes'
import LoginContainer from './login/LoginContainer';
import { getPricingDetails } from './OrderOption/OrderService';
import { GlobalContext, GlobalDispatchContext } from './context/Context';
import { START_LOADING, AVATAR_TYPES, AVATAR_CHOICE, STOP_LOADING } from './common/Constants';
import ReactGA from "react-ga4";


export default function RouterApp(props) {

  ReactGA.initialize(process.env.REACT_APP_GA_FOLLOW);
  const context = React.useContext(GlobalContext);
  const dispatch = React.useContext(GlobalDispatchContext)



  React.useEffect(() => {
    /* setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor(); */

  }, []);


  return (
    /*  <BrowserRouter history={history}>
       <Routes history={history}>
         <Route path="/" exact element={<LoginContainer />} />
         <Route path="/home" exact element={<DashBoardContainer />} />
         <Route path="/attribute" exact element={<AttributeContainer />} />
         <Route path="/createuser" exact element={<UserContainer />} />
       </Routes>
     </BrowserRouter> */

    <_Routes history={history} />

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<RouterApp />);


reportWebVitals();

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import LoginContainer from '../login/LoginContainer';
import { BUILD_VERSION, RENDER_URL, LOGOUT } from '../common/Constants'
import { httpInterceptor, setBaseUrl, setApplicationToken } from "../core/HttpService"
import { ManageLocalStorage } from "./LocalStorage";
import { ManageSessionStorage } from "./SessionStorage";
import HomeContainer from "../home/HomeContainer";
import SigninContainer from "../SignIn/SigninContainer";
import MysteryboxContainer from "../Mysterybox/MysteryboxContainer";
import ClaimContainer from "../ClaimOptionsContainer/ClaimContainer";
import OrderOptionContainer from "../OrderOption/OrderOptionContainer";
import PaymentContainer from "../Payment/PaymentContainer";
import StripeContainer from "../stripe/StripeContainer";
import SpinBoxContainer from "../spinbox/SpinBoxContainer";
import ThankyouContainer from "../Thankyou/ThankyouContainer";
import MyAccount from "../MyAccount/MyAccountContainer";
import MyAccountContainer from "../MyAccount/MyAccountContainer";
import PaymentSuccess from "../Payment/PaymentSuccess";
import PaymentDeclined from "../Payment/PaymentDeclined";
import SignInNewContainer from "../SignIn/SignInNewContainer";
import ConfirmContainer from "../confirm/ConfirmContainer";
import AssemblyContainer from "../confirm/AssemblyContainer";
import MysteryBoxNewContainer from "../Mysterybox/MysteryBoxNewContainer";
import LoginNewContainer from "../login/LoginNewContainer";
import SpinBoxNewContainer from "../spinbox/SpinBoxNewContainer";
import SpinFlowFinal from "../spinbox/SpinFlowFinal";
import ForgotPassword from "../login/ForgotPassword";
import ResetPassword from "../login/ResetPassword";
import { render } from "@testing-library/react";
import MbwayUserContainer from "../Payment/MbwayUserContainer";
import DashboardContainer from "../Dashboard/DashboardContainer";
import DashboardContainerNew from "../Dashboard/DashboardNewContainer";
import TwitterContainer from "../twitter/twitterContainer";


const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
    }

    setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor();


    const loginData = context.login;
    const _buildNo = ManageLocalStorage.get(BUILD_VERSION);
    console.log("buildNo", _buildNo);
    if (_buildNo == null || _buildNo != process.env.REACT_APP_BUILD_VERSION) {
        console.log("ENV", process.env.REACT_APP_BUILD_VERSION)
        clearBrowser(null);
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    } else {
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    }



    return (
        <Routes {...xProps}>
            <Route path={RENDER_URL.LOGIN_NEW_URL} exact element={<LoginNewContainer/>}/>
            <Route path={RENDER_URL.LOGIN_URL} exact element={loginData.isLoggedIn ? <Navigate to={RENDER_URL.ORDER_OPT_URL} /> : <Navigate to={RENDER_URL.ORDER_OPT_URL} /> } />
            <Route path={RENDER_URL.HOME_URL} exact element={(loginData.isLoggedIn ? <HomeContainer /> : <Navigate to={RENDER_URL.HOME_URL} />)} />
            <Route path={RENDER_URL.SIGNIN_URL} exact element={<SignInNewContainer />} />
            <Route path={RENDER_URL.SPIN_BOX_URL} exact element={<SpinBoxNewContainer />} />
            <Route path={RENDER_URL.MYSTERY_BOX_URL} exact element={<MysteryBoxNewContainer />} />
            <Route path={RENDER_URL.CLAIM_URL} exact element={<ClaimContainer />} />
            <Route path={RENDER_URL.ORDER_OPT_URL} exact element={<OrderOptionContainer />} />
            <Route path={RENDER_URL.PAYMENT_URL} exact element={<PaymentContainer />} />
            <Route path={RENDER_URL.STRIPE_PAY_URL} exact element={<StripeContainer />} />
            <Route path={RENDER_URL.THANKYOU_URL} exact element={<SpinFlowFinal/>}/>
            <Route path={RENDER_URL.MYACCOUNT_URL} exact element={<MyAccountContainer/>}/>
            <Route path={RENDER_URL.PAYMENT_SUCCESS} exact element={<PaymentSuccess/>}/>
            <Route path={RENDER_URL.PAYMENT_DECLINED} exact element={<PaymentDeclined/>}/>
            <Route path={RENDER_URL.CONFIRM_AVATAR} exact element={<ConfirmContainer/>}/>
            <Route path={RENDER_URL.ASSEMBLE_CONTAINER} exact element={<AssemblyContainer/>}/>
            <Route path={RENDER_URL.FORGOT_CONTAINER} exact element={<ForgotPassword/>}/>
            <Route path={RENDER_URL.RESET_CONTAINER} exact element={<ResetPassword/>}/>
            <Route path={RENDER_URL.STRIPE_USER} exact element={<MbwayUserContainer/>}/>
            <Route path={RENDER_URL.DASHBOARD} exact element={<DashboardContainerNew/>}/>
            <Route path="/dashtest" exact element={<DashboardContainerNew/>}/>
            <Route path={RENDER_URL.TWITTER} exact element={<TwitterContainer/>}/>
        </Routes>
    );
};
export default RootRouteGuard
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SelectedAvatarComponent from "./SelectedAvatarComponent";
import { RENDER_URL, SELECTED_AVATAR, START_LOADING, STOP_LOADING, LOCALISATION_ARRAY } from "../common/Constants";
import { claimAvatarEagleApi, getAvatarEagleApi } from "./MysteryService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import './AssemblyCSS.css';
import { saveAs } from 'file-saver';
import LocalizedStrings from "react-localization";
import { useSwipeable } from 'react-swipeable';
import TwitterButtonComponent from "../twitter/TwitterButtonComponent";

function MysteryBoxNewContainer() {

    //this is test
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);

    const [position, setPosition] = useState('bottom');
    const [inside, setInside] = useState(true);
    const [selectedAvatarType, setSelectAvatar] = useState(SELECTED_AVATAR.FIRST)
    const [count, setCount] = useState(0);
    const _history = useNavigate();
    const [showMask, setMaskVisibility] = useState(true);
    const [mysteryAvatar, setMysteryAvatar] = useState({})
    const [images, setImages] = useState([mysteryAvatar.booth_image]);
    const [enableClaim, setEnableClaimButton] = useState(false);

    const [showProfileImage, setProfileImage] = useState(true);

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const onNext = () => {
        _history("/payment");
    }

    useEffect(() => {

        loadAvatarsFromApi();

    }, []);


    const handlers = useSwipeable({
        onSwipedLeft: () => setProfileImage(!showProfileImage),
        onSwipedRight: () => setProfileImage(!showProfileImage),
        swipeDuration: 1000,
        trackMouse: true
    });


    const loadAvatarsFromApi = () => {

        /*   dispatch({
              type: START_LOADING,
          });
   */
        let P_UUID = context.login.PAYMENT_UUID;
        let choice = context.login.setSelectedBoxOption.id;
        let category = context.login.setSelectedOrderOption.id;
        let param = {
            payment_id: P_UUID,
            choice: choice,
            category: category
        }

        getAvatarEagleApi(param).then((response) => {
            if (response.data.success) {
                let avatar = response.data.attributes.avatars;
                if (avatar.length > 0) {
                    let eagle_avatar = avatar[0];
                    console.log("EAGLE", eagle_avatar);
                    setMysteryAvatar(eagle_avatar);
                    setEnableClaimButton(true);

                    /* dispatch({
                        type: STOP_LOADING,
                    }); */
                    setTimeout(() => {
                        onClaimMystreyEagle(eagle_avatar.avatar_id);
                        setMaskVisibility(false);
                    }, 5000);
                }
            }
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });
        });
    }


    const onClaimMystreyEagle = (id) => {



        let P_UUID = context.login.PAYMENT_UUID;
        let params = {
            payment_id: P_UUID,
            avatar_id: id,
            language: context.login.LANGUAGE_CODE
        }

        claimAvatarEagleApi(params).then((response) => {
            console.log(response);
            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

            console.log(error);

        })

    }

    const onSelectAvatar = (id_value) => {
        setCount(count + 1);
        setSelectAvatar(id_value);
    }

    const renderAttributes = () => {

        if (mysteryAvatar.metadata) {
            let count = 0;
            return (
                Object.entries(mysteryAvatar.metadata).map(([index, object]) => {
                    return <div key={index} className="attribute">
                        <span style={{ color: 'red' }}>{object.name}</span>
                        <span style={{ color: 'black' }}>{object.value ? object.value : 'none'}</span>
                        <span style={{ color: 'black' }}>{object.proportion ? `${object.proportion} %` : ''}</span>
                    </div>
                })
            )
        }

    }

    const itemTemplate = (item) => {
        return <img src={mysteryAvatar.booth_image} style={{ paddingBottom: '10px' }} className="img-responsive" />
    };

    const onDownloadClick = () => {

        saveAs(showProfileImage ? mysteryAvatar.profile_image : mysteryAvatar.booth_image, `${mysteryAvatar.name}_${mysteryAvatar.avatar_id}`)

    }

    const onOrderAgain = () => {

        _history(RENDER_URL.ORDER_OPT_URL);

    }


    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            {
                                !showMask ?
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                            <div className="AvatarChooseTitle" style={{ fontSize: '30px' }}>{WEB_STRINGS.CONGRATULATIONS}</div>
                                            <div className="AvatarChooseDesc">{WEB_STRINGS.MYSTERY_BOX_SUCCESS}</div>
                                        </div>

                                        <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2" style={{ marginTop: '2%' }}>
                                            <div className="row">
                                                <div className="col-lg-6 text-center" {...handlers}>
                                                    <div className="card" id="xxx" style={{ padding: '10px', height: '100%', minHeight: '500px' }}>
                                                        {
                                                            showProfileImage ?
                                                                <div className="bg-image" style={{ background: `url(${mysteryAvatar?.profile_image})`, borderRadius: '10px' }} onClick={() => { setProfileImage(!showProfileImage) }}>
                                                                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                        <img src="../assets/images/press-button.svg" width="12%" height="auto" style={{ float: 'left', paddingLeft: '10px', paddingTop: '10px' }} />
                                                                        <div style={{ bottom: '20px', left: '0', width: '100%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                            <span class={showProfileImage ? "dot_selected" : "dot"}></span>
                                                                            <span class="dot"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="bg-image booth" style={{ background: `url(${mysteryAvatar?.booth_image})` }} onClick={() => { setProfileImage(!showProfileImage) }} >
                                                                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                        <div style={{ bottom: '-20px', left: '0', width: '90%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                            <span class="dot"></span>
                                                                            <span class="dot_selected"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }

                                                        {/* <img src={mysteryAvatar.booth_image} style={{ paddingBottom: '10px', margin: 'auto', width: '175%', marginLeft: '-33%', marginBottom: '33%' }} className="img-responsive" /> */}

                                                        <div className="download_bottom">
                                                            <div>
                                                                <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.DOWNLOAD_IMAGES} onClick={() => { onDownloadClick() }}></Button>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="card shadow-lg">
                                                            <div className="attribute_div">
                                                                <div className="row back_white">
                                                                    <div className="col-auto p-0">
                                                                        <img src="../assets/images/logo.png" width='50px' />
                                                                    </div>
                                                                    <div className="col p-0">
                                                                        <h6 style={{ marginTop: '2%' }}>{mysteryAvatar.name}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin_test">
                                                                    <div className="col-sm-12">
                                                                        <div style={{ paddingRight: '40px', position: 'relative' }}>
                                                                            <div className='attributes'>
                                                                                {
                                                                                    renderAttributes()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: '10px' }}>
                                                        <div className="card shadow-lg">
                                                            {/* <div className="card-body">
                                                                <div className="follow_title">{WEB_STRINGS.FOLLOW_THESE_STEPS}</div>
                                                                <ol >
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_1}</li>
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }} >{WEB_STRINGS.STEP_2}</li>
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_3}</li>
                                                                </ol>
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                                        <Button label={WEB_STRINGS.FOLLOW_US} className="social_button twitter" onClick={() => { window.location.href = 'https://twitter.com/VictorySLB' }} icon="pi pi-twitter" />
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                                        <Button label={WEB_STRINGS.FOLLOW_US} className="social_button insta" icon="pi pi-instagram" onClick={() => { window.location.href = 'https://instagram.com/victory_slb' }} />
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                                        <Button label={WEB_STRINGS.ORDER_AGAIN} className="social_button" onClick={() => { onOrderAgain() }} />
                                                                    </div>
                                                                </div>
                                                            </div> */}


                                                            <div className="card-body">
                                                                <div className="follow_title">{WEB_STRINGS.FOLLOW_THESE_STEPS}</div>
                                                                <ol >
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_1}</li>
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }} >{WEB_STRINGS.STEP_2}</li>
                                                                    <li className="follow_instructions" style={{ fontSize: '14px', marginTop: '5px', marginBottom: '8px' }}>{WEB_STRINGS.STEP_3}</li>
                                                                </ol>
                                                                <div className="row">
                                                                    
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            <Button label={WEB_STRINGS.FOLLOW_US} className="social_button twitter" onClick={() => { window.location.href = 'https://twitter.com/VictorySLB' }} icon="pi pi-twitter" />
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            <Button label={WEB_STRINGS.FOLLOW_US} className="social_button insta" icon="pi pi-instagram" onClick={() => { window.location.href = 'https://instagram.com/victory_slb' }} />
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            <Button label={WEB_STRINGS.ORDER_AGAIN} className="social_button" onClick={() => { onOrderAgain() }} />
                                                                        </div>
                                                        
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    :
                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                        <div className="pl">
                                            <svg className="pl__rings" viewBox="0 0 128 128" width="128px" height="128px">
                                                <g fill="none" stroke-linecap="round" stroke-width="4">
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="pl__nucleus">
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                            </div>
                                        </div>
                                        <p className="text-center privacy_policy">{WEB_STRINGS.ASSEMBLE}</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MysteryBoxNewContainer
import { httpCall, httpFormCall, httpGetCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const getAvatarEagleApi = params => {
    return httpFormCall({
        url: API_URL.CHOOSE_AVATAR,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const claimAvatarEagleApi = params =>{
    return httpFormCall({
        url: API_URL.CLAIM_AVATAR,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}



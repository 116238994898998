import { httpCall, httpFormCall, httpGetCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const createSource = params => {
    return httpGetCall({
        url: `${process.env.REACT_APP_PAYMENT_URL}/create-source`,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};



export const createCharge = params => {
    return httpCall({
        url: `${process.env.REACT_APP_PAYMENT_URL}/charge`,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};



export const updatePaymentInfo = params => {
    return httpCall({
        url: API_URL.PAYMENT_UPDATE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}



//MBWAY SERVICES

export const mbWayInitiatePayment_1 = params => {
    return httpCall({
        url: API_URL.MBWAY_INITIATE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}


export const mbWayCheckStatus = params => {
    return httpGetCall({
        url: API_URL.MBWAY_STATUSCHECK,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}


export const mbWayConfirmPayment= params => {
    return httpFormCall({
        url: API_URL.MBWAY_CONFIRM,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}

export const myCouponValidate= params => {
    return httpFormCall({
        url: API_URL.COUPON_VALIDATE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
}





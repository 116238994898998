import React from 'react';

function FooterContainer(props){
    return (
        <>
        <div className='container'>
                    <div className='row foot_padding'>
                        <div className='col-sm-10 offset-2'>
                            <hr className="primary-color"></hr>
                            <img src='./assets/images/logo.png'className='main_logo'/>
                            <hr className="primary-color"></hr>
                            <p className='copyright'> ©2023 interverse -SL Benefica Product All rights reserved</p>
                        </div>
                    </div>
                </div>
        
        </>
    )
}
export default FooterContainer
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

let claim_card_css = {
    selected: 'card shadow-lg _card payment_border',
    not_selected: 'card shadow-lg _card'
}

function ClaimCardContainer(props) {

    const [selectedMethod, setSelectedMethod] = useState(props.isSelected.name);
    const [id, setSelectedID] = useState(props.id.name);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Option" });
    },[])

    useEffect(() => {
        if (props.isSelected.name == id) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [props.isSelected]);

    return (

        <div className='col-lg-6 col-md-6 col-sm-12 choose_box' onClick={() => { props.onclickfn() }}>
            <div className={isSelected ? claim_card_css.selected : claim_card_css.not_selected}>
                <div className="row">
                    <div className="col-sm-12 text-center" style={{marginTop:'5px', padding: '10px' }}>
                        <img src={props.image} width="30%" className="img-responsive" />
                    </div>
                    <div className="col-sm-12" >
                        <p className="order_card_title text-center">{props.title}</p>
                        <p className="claim_card_desc text-center" style={{minHeight:'100px'}}>{props.description}</p>
                        <hr style={{ marginLeft: "15px", marginRight: "15px" }}></hr>
                        <p className="order_card_desc" style={{float:'right',paddingRight:'10px',fontSize:'17px',fontWeight:'700'}}>€{props.price}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ClaimCardContainer
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { RENDER_URL, LOCALISATION_ARRAY } from "../common/Constants";
import LocalizedStrings from "react-localization";
import ReactGA from "react-ga4";

function PaymentSuccess(props) {

    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);
    useEffect(() => {

        try {
            ReactGA.send({
                hitType: "event",
                eventCategory: "Web Vitals",
                eventAction: "PaymentSuccess",
                eventLabel: `paymentBy`,
                nonInteraction: true,
                // Built-in params:
                value: context.login.PAYMENT_UUID, // Use delta so the value can be summed.
                // Custom params:
                payment_id: context.login.PAYMENT_UUID,
                payment_user: context.login.userData ? parseInt(context.login.userData.uuid.split("BFCA_")[1]) : null

            })
        } catch (error) {
            console.log("error", error);
        }



    }, []);


    const onNext = () => {
        let selectedBoxType = context.login.setSelectedBoxOption;
        console.log(">>>", selectedBoxType);
        if (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) {
            _history('/mbox');
        } else {
            _history('/spin');
        }
    }

    const onCreateButtonClick = () => {
        _history(`${RENDER_URL.SIGNIN_URL}`);
    }




    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">

                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <img className="img img-responsive" style={{ padding: '5px', marginBottom: '10px' }} src="../assets/images/correct.png" width="93px" height="93px" />
                                                    <p className="payment_success_title">{WEB_STRINGS.PAYMENT_SUCCESS_TITLE}</p>
                                                    <p className="payment_success_desc">{WEB_STRINGS.PAYMENT_SUCCESS_DESC}</p>

                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.CREATE_ACCOUNT} onClick={() => { onCreateButtonClick() }} />

                                                    <p className="text-center privacy_policy">
                                                        {WEB_STRINGS.FOOTER_SUCCES}<a href="https://benfica.interverse.ai/privacy-policy/" target="_blank" style={{ marginLeft: '5px' }}>{WEB_STRINGS.PRIVACY_POLICY}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default PaymentSuccess;
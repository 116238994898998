import { httpCall, httpFormCall, httpGetCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const signUpApi = params => {
    return httpFormCall({
        url: API_URL.SIGN_UP_API,
        method: "POST",
        data: params
    });
};


export const loginApi = params => {
    return httpFormCall({
        url: API_URL.LOGIN_API,
        method: "POST",
        data: params
    });
};


export const forgotApi = params => {
    return httpFormCall({
        url: API_URL.FORGOT_API,
        method: "POST",
        data: params
    });
}



import React, { useEffect, useState, useRef } from "react";
import PaymentCard from "./PaymentCard";
import { Button } from "primereact/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CHOOSE_OPTION, PAYMENT_TYPE, PAYMENT_UUID, RENDER_URL, START_LOADING, STOP_LOADING, LOCALISATION_ARRAY, COUPON_CODE, generateUUID } from "../common/Constants";
import { createSource, createCharge, updatePaymentInfo, myCouponValidate } from "../Payment/PaymentService";
import LevelContainer from "../Level/LevelContainer";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { ManageLocalStorage } from "../core/LocalStorage";
import { Toast } from "primereact/toast";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import LocalizedStrings from "react-localization";
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';


function PaymentContainer() {
    const toast = useRef(null);

    const _history = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("source")
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    const [sourceValue, setSourceValue] = useState(null);

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const [couponActive, setCouponActive] = useState(false);
    const [couponText, setCouponText] = useState('');
    const [couponUUID, setCouponUUID] = useState('');
    const [applyCouponOpen, setApplyCouponOpen] = useState(false);
    const [isValidCoupon, setAsValidCoupon] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponError, setCouponError] = useState("");
    const [isError, setIsError] = useState(false);
    useEffect(() => {

        dispatch({
            type: COUPON_CODE,
            payload: undefined,
        })

    }, []);

    useEffect(() => {
        let z = searchParams.get('source');
        if (z != null) {

            let selectedOrderOption = context.login.setSelectedOrderOption;
            let selectedChoiceOption = context.login.setSelectedBoxOption;

            const _sourceData = {
                _currentChargeSource: z,
                productType: `${selectedOrderOption.id}`,
                choiceType: `${selectedChoiceOption.id}`
            }

            dispatch({
                type: START_LOADING,
            });



            createCharge(_sourceData).then((response) => {
                console.log(response);
                if (response.data) {

                    let _data = response.data;
                    let outcome = _data.outcome;
                    if (outcome) {
                        onUpdatePaymentInfo(_data);

                    }

                }
            }).catch((error) => {

                _history(`${RENDER_URL.PAYMENT_DECLINED}`)

                dispatch({
                    type: STOP_LOADING,
                });
                console.log(error);
            })

        }
    }, [searchParams.get("source")]);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_TYPE.VISA)
    const [count, setCount] = useState(0);

    const onNext = () => {
        if (discountAmount == "100") {
            debugger;
            let detail = {
                "amount": 0,
                "id": couponUUID == "free_BENFICA23" ? generateUUID() : couponUUID
            }

            onUpdatePaymentInfo(detail);


        }
        else {
            if (selectedPaymentMethod == PAYMENT_TYPE.VISA) {
                _history(`/stripe_pay`)
            }
            else {
                _history(`/mbway`);
                //onMBWayPayment();
            }
        }

    }

    const onUpdatePaymentInfo = (_details) => {

        dispatch({
            type: START_LOADING,
        });


        let selectedOrderOption = context.login.setSelectedOrderOption;
        let selectedChoiceOption = context.login.setSelectedBoxOption;
        let userData = context.login.userData;
        let amount = _details.amount;
        let uuid = _details.id;


        let data = {};
        if (isValidCoupon) {
            data = {
                "uuid": uuid,
                "product_choice": selectedChoiceOption.id,
                "product_type": selectedOrderOption.id,
                "user_id": userData ? parseInt(userData.uuid.split("BFCA_")[1]) : null,
                "amount": amount,
                "currency": "euro",
                "metadata": _details,
                "coupon": couponText
            }
        }
        else {
            data = {
                "uuid": uuid,
                "product_choice": selectedChoiceOption.id,
                "product_type": selectedOrderOption.id,
                "user_id": userData ? parseInt(userData.uuid.split("BFCA_")[1]) : null,
                "amount": amount,
                "currency": "euro",
                "metadata": _details
            }
        }


        updatePaymentInfo(JSON.stringify(data)).then((response) => {


            console.log("payment update response", response);

            if (response.data.success) {
                //showSuccess('success', WEB_STRINGS.SUCCESS,WEB_STRINGS.PAYMENT_SUCCESSFUL);

                ManageLocalStorage.set(PAYMENT_UUID, uuid);
                dispatch({
                    type: PAYMENT_UUID,
                    payload: uuid,
                });

                dispatch({
                    type: STOP_LOADING,
                });

                setTimeout(() => {
                    _history(`${RENDER_URL.PAYMENT_SUCCESS}`)
                }, 1000);
            }

        }).catch((error) => {
            console.log("payment update error", error);
            dispatch({
                type: STOP_LOADING
            });
            _history(`${RENDER_URL.PAYMENT_DECLINED}`)
        })


    }


    const onMBWayPayment = () => {

        dispatch({
            type: START_LOADING,
        });

        let selectedOrderOption = context.login.setSelectedOrderOption;
        let selectedChoiceOption = context.login.setSelectedBoxOption;

        let params = { productType: `${selectedOrderOption.id}`, choiceType: `${selectedChoiceOption.id}` }


        createSource(params).then((response) => {

            if (response.data) {

                dispatch({
                    type: STOP_LOADING,
                });

                let mainresponse = response.data;
                let redirectionURL = mainresponse.redirect.url;
                if (redirectionURL) {
                    console.log(redirectionURL);
                    window.location.replace(redirectionURL);
                }


            }
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: STOP_LOADING,
            });
        });
    }

    const onWhichPaymentMethod = (id_value) => {
        setCount(count + 1);
        setSelectedPaymentMethod(id_value);

    }

    const showSuccess = (severity, summary, messageTo) => {
        toast.current.show({ severity: `${severity}`, summary: `${summary}`, detail: `${messageTo}`, life: 3000 });
    }

    const onBack = () => {
        _history('/claim');
    }

    const calculateTotal = () => {

        let selectedOrderOption = context.login.setSelectedOrderOption;
        let selectedChoiceOption = context.login.setSelectedBoxOption;
        let Total = parseFloat(selectedOrderOption.price) + parseFloat(selectedChoiceOption.price);
        if (isValidCoupon) {
            Total = (Total - ((Total * discountAmount) / 100));
        }
        console.log(">>>total", Total);
        return Total.toFixed(2);

    }

    const getCategoryPrice = () => {
        let selectedOrderOption = context.login.setSelectedOrderOption;
        console.log("getcatrogyr price", selectedOrderOption);
        return selectedOrderOption.price;
    }
    const getChoicePrice = () => {
        let selectedChoiceOption = context.login.setSelectedBoxOption;
        return selectedChoiceOption.price;
    }

    const onCouponValidate = () => {

        dispatch({
            type: START_LOADING,
        });

        setCouponError("");

        if (couponText.length <= 0) {
            setIsError(true)
            setCouponError(WEB_STRINGS.ENTER_VALID_COUPON);
            dispatch({
                type: STOP_LOADING,
            });

        }
        else {
            let data = {
                coupon: `${couponText}`,
                productType:context.login.setSelectedOrderOption.id
            }

            myCouponValidate(data).then((response) => {

                dispatch({
                    type: STOP_LOADING,
                });

                if (response.data.success) {
                    let percentage = response.data.data.discount;
                    let couponUUIDCode = response.data.data.code;

                    dispatch({
                        type: COUPON_CODE,
                        payload: couponUUIDCode,
                    })

                    setCouponUUID(couponUUIDCode);
                    setDiscountAmount(percentage);
                    setAsValidCoupon(true);
                    setCouponActive(false);
                    setIsError(false);
                    setCouponError(WEB_STRINGS.VALID_COUPON)
                }
                else {
                    setIsError(true);
                    setCouponError(WEB_STRINGS.NOTVALID_COUPON)
                }
            }).catch((error) => {
                setIsError(true)
                setCouponError(WEB_STRINGS.NOTVALID_COUPON)
                console.log(error, "error");
            })
        }

    }


    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <Toast ref={toast} />

                        <div className="col-sm-8 main_section">
                            <LogoHeader></LogoHeader>
                            {/* <div className="row" style={{ marginTop: '50px' }}>
                                <div className='col-sm-12'>
                                    <LevelContainer levelCount={5} completed={3}></LevelContainer>
                                </div>
                            </div> */}

                            <div className="row">
                                <h4 className="main_titles">{WEB_STRINGS.PAYMENT_TITLE}</h4>
                            </div>

                            <div className="row justify-content-center" style={{ marginTop: '10px' }}>
                                <PaymentCard isSelected={selectedPaymentMethod} card={WEB_STRINGS.CARD_TYPE} img_url={"../assets/images/Visa_Mastercard.png"} id={PAYMENT_TYPE.VISA} onclickfn={() => { onWhichPaymentMethod(PAYMENT_TYPE.VISA) }} />
                                <PaymentCard isSelected={selectedPaymentMethod} card={"MB Way"} img_url={"../assets/images/2560px-Logo_MBWay.svg.png"} id={PAYMENT_TYPE.MB_WAY} onclickfn={() => { onWhichPaymentMethod(PAYMENT_TYPE.MB_WAY) }} />
                            </div>

                            <div className="row p-0 mt-3">
                                <div className="col-auto">
                                    <div className="row justify-content-center">
                                        <Inplace active={couponActive} onOpen={() => setApplyCouponOpen(true)}>
                                            <InplaceDisplay>{couponText || WEB_STRINGS.APPLY_COUPON_CODE}</InplaceDisplay>
                                            <InplaceContent>
                                                <InputText value={couponText} onChange={(e) => setCouponText(e.target.value)} autoFocus />
                                                {isValidCoupon ? "" : <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_continue" style={{ marginLeft: '10px' }} label={WEB_STRINGS.APPLY} onClick={() => { onCouponValidate() }} disabled={isValidCoupon} ></Button>}
                                            </InplaceContent>
                                        </Inplace>
                                        {
                                            couponError && <span className={isError ? "p-myerror p-3" : "p-mysuccess p-3"}>{couponError}</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row btn_pad">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                    <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_back" label={WEB_STRINGS.BACK} onClick={() => { onBack() }}></Button>
                                    <Button className="btn btn-lg btn-md btn-sm btn-default btn_claim_continue" label={WEB_STRINGS.CONTINUE} onClick={() => { onNext() }}></Button>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order_total_price">
                                    <p className="p_pricing">{WEB_STRINGS.COMMON} :€{getCategoryPrice()}</p>
                                    <p className="p_pricing">{WEB_STRINGS.CHOICE} :€{getChoicePrice()}</p>
                                    {isValidCoupon ? <p className="p_pricing">{WEB_STRINGS.DISCOUNT} :{discountAmount}%</p> : <></>}
                                    <hr></hr>
                                    <p className="p_pricing">Total  : €{calculateTotal()}</p>
                                </div>
                            </div>


                            {/* <div className="row">
                                <div className="col-sm-12">
                                    <hr></hr>
                                    <p style={{ float: 'right' }}>Total : $4.99 </p>
                                </div>
                            </div> */}
                            {/* 
                            <div className="row signin_foot">
                                <hr className="primary-color"></hr>
                                <p className="footer_text"> ©2023 interverse -SL Benefica Product All rights reserved</p>
                            </div> */}




                        </div>
                        <div className="col-sm-4 image_payment">

                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}
export default PaymentContainer
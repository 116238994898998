import React, { useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import LocalizedStrings from "react-localization";
import { LOCALISATION_ARRAY, MBWAY_PAYMENTSTATUS, PAYMENT_UUID, RENDER_URL, generateUUID } from "../common/Constants";
import { GlobalContext, GlobalDispatchContext } from "../context/Context";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { mbWayCheckStatus, mbWayConfirmPayment, mbWayInitiatePayment_1 } from "./PaymentService";
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { ManageLocalStorage } from "../core/LocalStorage";
import { parsePhoneNumber } from "react-phone-number-input";


function MbwayUserContainer(pros) {

    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);
    const [mobileNumber, setMobileNumber] = useState()
    const [mobile_error, setMobileError] = useState("");
    const [responseUUID, setResponseUUID] = useState(undefined);
    const [onGoStatus, setOnGoStatus] = useState(MBWAY_PAYMENTSTATUS.WAITING);
    const [visible, setVisible] = useState(false);

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);



    const onMBWayPaymentInitiated = () => {

        if (mobileNumber == "") {
            setMobileError("Enter Mobile Number");
            return;
        }
        else {

            let selectedOrderOption = context.login.setSelectedOrderOption;
            let selectedChoiceOption = context.login.setSelectedBoxOption;
            let coupon = context.login.COUPON_CODE;

            let _uuid = generateUUID();

            const phoneNumber = parsePhoneNumber(`${mobileNumber}`);
            
            const formated_number = `+${phoneNumber.countryCallingCode}-${phoneNumber.nationalNumber}`

            let data = {};
            if(coupon){
                data = {
                    "uuid": `${_uuid}`,
                    "product_choice": `${selectedChoiceOption.id}`,
                    "product_type": `${selectedOrderOption.id}`,
                    "phone": `${formated_number}`,
                    "coupon":coupon
                }
            }
            else{
                data = {
                    "uuid": `${_uuid}`,
                    "product_choice": `${selectedChoiceOption.id}`,
                    "product_type": `${selectedOrderOption.id}`,
                    "phone": `${formated_number}`
                }
            }
            
            mbWayInitiatePayment_1(JSON.stringify(data)).then((response) => {

                console.log(response, "response mbway initiate");
                if (response.data.success) {

                    setVisible(true)
                    setResponseUUID(response.data.data.uuid);
                    checkForStatusChange(response.data.data.uuid);

                }
                else {
                    //todo exception handles
                }

            }).catch((error) => {
                console.log(error, "Error mbWay intiate");
            })

        }

    }

    const checkForStatusChange = (_uuid) => {

        console.log(_uuid, "first");

        let _status_timer = setInterval(async () => {

            console.log(_uuid, "second");
            let params = { uuid: `${_uuid}` }

            mbWayCheckStatus(params).then((response) => {
                if (response.data.success) {
                    let _status = response.data.data.payment;
                    if (MBWAY_PAYMENTSTATUS[_status] == MBWAY_PAYMENTSTATUS.DONE) {
                        console.log("completed", MBWAY_PAYMENTSTATUS[_status]);
                        setOnGoStatus(MBWAY_PAYMENTSTATUS[_status]);
                        onConfirmMBWayPayment(responseUUID ? responseUUID : _uuid);
                        clearInterval(_status_timer);
                    }
                    else if (MBWAY_PAYMENTSTATUS[_status] == MBWAY_PAYMENTSTATUS.FAILED) {
                        setOnGoStatus(MBWAY_PAYMENTSTATUS[_status]);
                        clearInterval(_status_timer);
                        _history(`${RENDER_URL.PAYMENT_DECLINED}`)
                    }
                    else if (MBWAY_PAYMENTSTATUS[_status] == MBWAY_PAYMENTSTATUS.UNKNOWN) {
                        setOnGoStatus(MBWAY_PAYMENTSTATUS[_status]);
                        clearInterval(_status_timer);
                        _history(`${RENDER_URL.PAYMENT_DECLINED}`)
                    }
                    else {
                        setOnGoStatus(MBWAY_PAYMENTSTATUS[_status]);
                    }
                }
            }).catch((error) => {

                _history(`${RENDER_URL.PAYMENT_DECLINED}`)
            })

        }, 3000);

    }


    const onConfirmMBWayPayment = (_uuid) => {

        let data = {
            uuid: `${_uuid}`
        }



        mbWayConfirmPayment(data).then((response) => {
            if (response.data.success) {

                ManageLocalStorage.set(PAYMENT_UUID, _uuid);
                dispatch({
                    type: PAYMENT_UUID,
                    payload: _uuid,
                });

                _history(`${RENDER_URL.PAYMENT_SUCCESS}`)


            }
        }).catch((error) => {

        });
    }

    const onNext = () => {

    }

    return (
        <>
            <section className="section">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 main_section">
                            <LogoHeader></LogoHeader>

                            <Dialog visible={visible} closable={false} style={{ width: '10vw' }} >
                                {
                                    (onGoStatus == MBWAY_PAYMENTSTATUS.DONE) ?
                                        <div>
                                            <div className="text-center">
                                                <Avatar icon="pi pi-check" style={{ backgroundColor: 'green', color: '#ffffff' }} size="large" shape="circle" />
                                            </div>
                                            <br></br>
                                            <div className="text-center">
                                                <b>{MBWAY_PAYMENTSTATUS.DONE}</b>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <ProgressSpinner />
                                            <div className="text-center">
                                                <b>{onGoStatus}</b>
                                            </div>
                                        </div>
                                }
                            </Dialog>

                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="card shadow-lg">
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <img src="../assets/images/2560px-Logo_MBWay.svg.png" width="100px" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlInput1">{WEB_STRINGS.MOBILE_NUMBER}</label>
                                            <PhoneInput
                                                className="form-control"
                                                international={true}
                                                defaultCountry="PT"
                                                value={mobileNumber}
                                                onChange={setMobileNumber} />
                                                
                                            <span className="p-error block">{mobile_error}</span>
                                            <Button type="button" className="btn btn-lg btn-md btn-sm btn-default" severity="danger" label={WEB_STRINGS.SUBMIT} onClick={() => { onMBWayPaymentInitiated() }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 image_payment d-sm-block">
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MbwayUserContainer;
import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import FooterContainer from "../footer/FooterContainer";
import { useNavigate } from "react-router-dom";
import { loginApi, signUpApi } from "./SignService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, USER_DATA, CHOOSE_OPTION, USER_TOKEN, RENDER_URL, LOCALISATION_ARRAY } from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Toast } from "primereact/toast";
import LocalizedStrings from "react-localization";
import ReactGA from "react-ga4";

function SignInNewContainer(props) {


    
    const toast = useRef(null);

    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')

    const [name_error, setNameError] = useState("");
    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");
    const [error_msg, setErrorMsg] = useState()

   

    useEffect(() => {

        let userData = context.login.userData;
        if (userData) {
            onNext();
        }

        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Sign Up" });

    }, []);

    const clearValidation = () => {
        setNameError("");
        setUserError("");
        setPasswordError("");
    }


    const onSignInAction = () => {

        clearValidation();

        if (name.length <= 0) {
            //showSuccess('error',WEB_STRINGS.ERROR,WEB_STRINGS.ENTER_VALID_NAME)
            setNameError(WEB_STRINGS.ENTER_VALID_NAME);
            return;
        }
        else if (username.length <= 0) {
            //showSuccess('error',WEB_STRINGS.ERROR,WEB_STRINGS.ENTER_VALID_EMAIL)
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else if (!validateEmail(username)) {
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else if (password.length < 8) {
            //showSuccess('error',WEB_STRINGS.ERROR,WEB_STRINGS.PASSWORD_LENGTH_VALIDATION)
            setPasswordError(WEB_STRINGS.PASSWORD_LENGTH_VALIDATION);
            return;
        }
        else {
            let P_UUID = context.login.PAYMENT_UUID;

            if (P_UUID) {
                let param = {
                    name: name,
                    email: username,
                    password: password,
                    payment_id: P_UUID
                }

                signUpApi(param).then((response) => {
                    console.log(response);
                    if (response.data.success) {
                        backgroundLogin(username, password);
                    }
                    else {
                        setErrorMsg(WEB_STRINGS.ERROR);
                        //showSuccess('error', WEB_STRINGS.ERROR, response.data.error)
                    }
                }).catch((error) => {

                    console.log(error);
                })
            }
            else {
                let param = {
                    name: name,
                    email: username,
                    password: password,
                }

                signUpApi(param).then((response) => {
                    console.log(response);
                    if (response.data.success) {
                        backgroundLogin(username, password);
                    }
                    else {
                        setErrorMsg(WEB_STRINGS.ERROR);
                        //showSuccess('error', WEB_STRINGS.ERROR, response.data.error)
                    }
                }).catch((error) => {

                    console.log(error);
                })
                
            }
        }
    }

    const showSuccess = (severity, summary, messageTo) => {
        toast.current.show({ severity: `${severity}`, summary: `${summary}`, detail: `${messageTo}`, life: 3000 });
    }

    const backgroundLogin = (username, password) => {

        let param = {
            email: username,
            password: password,
        }
        loginApi(param).then((response) => {
            if (response.data.success) {
                let userData = {
                    user: response.data.user,
                    uuid: response.data.uuid,
                    id: response.data.uuid.split("BFCA_")[1],
                    token: response.data.token
                }

                ManageLocalStorage.set(USER_DATA, userData);
                ManageLocalStorage.set(USER_TOKEN, userData.token);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });

                onNext();


            }
        }).catch((error) => {
            console.log("ERROR", error);
        })

    }


    const onNext = () => {
        debugger;
        let isDashboard = context.login.FROM_DASHBOARD;
        if (isDashboard) {

            _history(RENDER_URL.DASHBOARD);

        } else {
            let selectedBoxType = context.login.setSelectedBoxOption;
            console.log(">>>", selectedBoxType);
            if (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) {
                _history('/confirm');
            } else {
                _history('/confirm');
            }
        }
    }



    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <Toast ref={toast} />
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                    <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="sign_header">{WEB_STRINGS.CREATE_ACCOUNT}</div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 px-2">
                                                    <div class="form-group">
                                                        <label for="exampleFormControlInput1">{WEB_STRINGS.NAME}</label>
                                                        <input type="text" class="form-control form-control-md" id="exampleFormControlInput1" placeholder={WEB_STRINGS.NAME} onChange={(e) => setName(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{name_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 px-2">
                                                    <div class="form-group">
                                                        <label for="exampleFormControlInput1">Email</label>
                                                        <input type="email" class="form-control form-control-md" id="exampleFormControlInput1" placeholder={WEB_STRINGS.EMAIL_PLACEHOLDER} onChange={(e) => setUsername(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{username_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="inputPassword2" class="sr-only">Password</label>
                                                        <input type="password" class="form-control" id="inputPassword2" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{password_error}</small>
                                                    </div>
                                                </div>

                                                {
                                                    error_msg && <div className="form-group">
                                                        <span className="p-float-label text-center">
                                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                                        </span>
                                                    </div>
                                                }

                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: '15px', marginBottom: '20px' }}>
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.CREATE_ACCOUNT} onClick={() => { onSignInAction() }} />
                                                </div>
                                                <div className="row text-center">
                                                    <p><a href={RENDER_URL.LOGIN_NEW_URL} target="_self">{WEB_STRINGS.CLICK_HERE_TO_LOGIN}</a></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        </>
    )
}

export default SignInNewContainer;
import React, { Component, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LOGOUT, RENDER_URL } from './Constants';
import { ManageSessionStorage } from "../core/SessionStorage";

function HeaderContainer(props) {

    const location = useLocation(); // once ready it returns the 'window.location' object
    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();

    const [currentTab, setCurrentTab] = useState(RENDER_URL.HOME_URL);

    const clearBrowser = (e) => {

        e.preventDefault();
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();

    }

    useEffect(() => {
        console.log(location.pathname);
        setCurrentTab(location.pathname);
    }, [location]);



    const isActive = (value) => {
        return value == currentTab;
    }


    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light fixed-top">
                <div className="container">
                    <a className="navbar-brand mx-auto d-lg-none" href="index.html">

                        <img src='./assets/images/logo.png' className='main_logo' />

                    </a>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <a className="navbar-brand d-none d-lg-block">
                                <img src='./assets/images/logo.png' className='main_logo' />
                            </a>
                        </ul>
                    </div>
                </div>
            </nav>



            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}
        </>

    )

}

export default HeaderContainer;
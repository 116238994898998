import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SelectedAvatarComponent from "./SelectedAvatarComponent";
import { SELECTED_AVATAR, START_LOADING, STOP_LOADING } from "../common/Constants";
import { claimAvatarEagleApi, getAvatarEagleApi } from "./MysteryService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import UnLeadHeader from "../common/UnLeadHeader";


function MysteryboxContainer() {
    

    
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)
    const [selectedAvatarType, setSelectAvatar] = useState(SELECTED_AVATAR.FIRST)
    const [count, setCount] = useState(0);
    const _history = useNavigate();
    const [showMask, setMaskVisibility] = useState(true);
    const [mysteryAvatar, setMysteryAvatar] = useState({})
    const [enableClaim, setEnableClaimButton] = useState(false);

    const onNext = () => {
        _history("/payment");
    }

    const loadAvatarsFromApi = () => {

        dispatch({
            type: START_LOADING,
        });

        let P_UUID = context.login.PAYMENT_UUID;
        let choice = context.login.setSelectedBoxOption.id;
        let category = context.login.setSelectedOrderOption.id;
        let param = {
            payment_id: P_UUID,
            choice: choice,
            category: category
        }

        getAvatarEagleApi(param).then((response) => {
            if (response.data.success) {
                let avatar = response.data.attributes.avatars;
                if (avatar.length > 0) {
                    let eagle_avatar = avatar[0];
                    console.log("EAGLE", eagle_avatar);
                    setMaskVisibility(false);
                    setMysteryAvatar(eagle_avatar);
                    setEnableClaimButton(true);
                    dispatch({
                        type: STOP_LOADING,
                    });
                }
            }
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });
        });
    }


    const onClaimMystreyEagle = ()=>{

        dispatch({
            type: START_LOADING,
        });

        let P_UUID = context.login.PAYMENT_UUID;
        let params = {
            payment_id: P_UUID,
            avatar_id:mysteryAvatar.avatar_id,
            language:context.login.LANGUAGE_CODE
        }

        claimAvatarEagleApi(params).then((response)=>{
            console.log(response);
            if(response.data.success){
                dispatch({
                    type: STOP_LOADING,
                });
                _history('/thankyou')
            }

        }).catch((error)=>{

            dispatch({
                type: STOP_LOADING,
            });

            console.log(error);

        })
        
    }

    const onSelectAvatar = (id_value) => {
        setCount(count + 1);
        setSelectAvatar(id_value);
    }

    const renderAttributes = () => {

        if (mysteryAvatar.metadata) {
            let count = 0;
            return (
                Object.entries(mysteryAvatar.metadata).map(([index, object]) => {
                    let keys = Object.keys(mysteryAvatar.metadata)[count]
                    count++;
                    return <div key={index} className="attribute">
                        <span style={{ color: 'red' }}>{keys}</span>
                        <span style={{ color: 'black' }}>{object}</span>
                    </div>
                })
            )
        }

    }

    return (
        <>
            <section className="section abstract_bg">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-10">
                                    {/* <img src="./assets/images/logo.png"/> */}
                                </div>
                                <div className="col-sm-2 ">
                                    <img className="logo_mystery" src="./assets/images/logo.png" />
                                </div>
                            </div>
                            <div className="row">
                                <h2 className="h_deco"> MYSTERY BOX</h2>
                            </div>
                            <div className="row">
                                <div className={showMask ? "col-sm-2 offset-2 mystery_img_div1 _mask_view" : "col-sm-2 offset-2 mystery_img_div1"}>
                                    <div className="marquee">
                                        <div className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                        <div aria-hidden="true" className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="col-sm-12">
                                        <div className={showMask ? "card card_center _mask_view" : "card card_center"} style={{ borderRadius: '10px' }}>
                                            <div className="row back_white">
                                                <div className="col-sm-2">
                                                    <img src="../assets/images/logo.png" width='50px' />
                                                </div>
                                                <div className="col-sm-10">
                                                    <h6>{mysteryAvatar.name}</h6>
                                                </div>
                                            </div>
                                            <div className="row back_white attribute_title">
                                                <div className="col-sm-12">
                                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Attributes</p>
                                                </div>
                                            </div>
                                            <div className="row margin_test">
                                                <div className="col-sm-12">
                                                    <div style={{ paddingRight: '40px', position: 'relative' }}>
                                                        <div className='attributes'>
                                                            {/* <div className="attribute" >
                                                                <span>Hat</span>
                                                                <span>Pirate Hat</span>
                                                                <span style={{ fontSize: '10px' }}>22% have this traits</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span>HEAD</span>
                                                            </div>
                                                            <div className="attribute" >
                                                                <span>NOSE</span>
                                                            </div> */}
                                                            {
                                                                renderAttributes()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="btn_pad_generate_avatar text-center">

                                            {enableClaim ? <input type="button" className="btn btn-danger" value="CLAIM YOUR EAGLE" onClick={() => { onClaimMystreyEagle() }} />
                                                :
                                                <input type="button" className="btn btn-danger" value="GENERATE MY VICTORY AVATAR" onClick={() => { loadAvatarsFromApi() }} />
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={showMask ? "col-sm-2 mystery_img_div2 _mask_view" : "col-sm-2 mystery_img_div2"}>
                                    <div className="marquee">
                                        <div className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />

                                        </div>
                                        <div aria-hidden="true" className="marquee__group_no">
                                            <img src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NTk0MDQ3MzQ&ixlib=rb-1.2.1&q=80&w=400" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row signin_foot">
                                <hr className="primary-color"></hr>
                                <p className="footer_text"> ©2023 interverse -SL Benefica Product All rights reserved</p>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}
export default MysteryboxContainer
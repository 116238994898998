import React, { Component, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LANGUAGE_CODE, LOGOUT, RENDER_URL } from './Constants';
import { ManageSessionStorage } from "../core/SessionStorage";
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';


function LogoHeader(props) {
    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(null);

    let items = [
        {
            label: 'Logout', icon: 'pi pi-fw pi-user', command: () => { onLogout() }
        }
    ];

    const onlanguageSelected = (code) => {
        if (code == 'EN') {
            dispatch({
                type: LANGUAGE_CODE,
                payload: 'en',
            });
        } else if (code == 'PT') {
            dispatch({
                type: LANGUAGE_CODE,
                payload: 'pt',
            });
        }
    }

    const onLogout = (e) => {
        clearBrowser(e);
    }

    const clearBrowser = (e) => {

        if (e) { e.preventDefault(); }
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        _history(RENDER_URL.ORDER_OPT_URL);

    }



    return (

        <div className='row'>
            <div className='col-lg-12 col-sm-12'>
                <img src='../assets/images/Logo_Interverse_Benfica.png' className='img img-responsive' style={{ maxWidth: '200px' }} />

                 <div style={{ float: 'right', cursor: 'pointer' }}>
                    <Tooltip target=".logout_man" content={`Logout`}/>
                    <span className='text-center m-3 logout_man' onClick={(e)=>{onLogout(e)}}>{props.showProfile? context.login.userData?.user:"" }</span>
                    <span onClick={() => { onlanguageSelected('EN') }}><img src='https://benfica.interverse.ai/wp-content/uploads/2023/05/uk.png' style={{ width: '30px' }} /> <span style={{ marginRight: '10px' }}><b>EN</b></span></span>
                    <span onClick={() => { onlanguageSelected('PT') }}><img src='https://benfica.interverse.ai/wp-content/uploads/2023/05/portugal.png' style={{ width: '30px' }} /> <span style={{ marginRight: '10px' }}><b>PT</b></span></span>
                </div> 

               {/*  <div className='logout_menu' style={{ float: 'right', position: 'relative' }}>
                    <Badge value="BJ" size="large" severity="danger"></Badge>
                    <div className="hidden">
                        <Menu model={items} />
                    </div>
                </div>
                <div className='flag_menu' style={{ float: 'right' }}>
                    <span onClick={() => { onlanguageSelected('EN') }}>
                        <img src='https://benfica.interverse.ai/wp-content/uploads/2023/05/uk.png' style={{ width: '30px', marginRight: '5px', marginTop: '2px' }} />
                    </span>
                </div> */}

            </div>

        </div>

    )

}

export default LogoHeader;
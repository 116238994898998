import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement
} from "@stripe/react-stripe-js";
import { GlobalDispatchContext, GlobalContext } from "../context/Context";
import { API_URL, PAYMENT_UUID, RENDER_URL, START_LOADING, STOP_LOADING, LOCALISATION_ARRAY } from "../common/Constants";
import { updatePaymentInfo } from "../Payment/PaymentService";
import { ManageLocalStorage } from "../core/LocalStorage";
import LocalizedStrings from "react-localization";
import ReactGA from "react-ga4";

export default function CheckoutForm(props) {

  const stripe = useStripe();
  const elements = useElements();
  const _history = useNavigate();

  const dispatch = React.useContext(GlobalDispatchContext)
  const context = React.useContext(GlobalContext);

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [displayForm, setDisplayForm] = useState('none')

  let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
  WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

  useEffect(() => {

    dispatch({
      type: START_LOADING,
    });


    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      dispatch({
        type: STOP_LOADING
      });

      setDisplayForm('block');
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

      setDisplayForm('none');

      console.log(">>>> payment details>>>>", paymentIntent)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          onUpdatePaymentInfo(paymentIntent);
          //onPaymentSuccess();
          break;
        case "processing":
          setMessage("Your payment is processing.");
          dispatch({
            type: STOP_LOADING,
          });
          onPaymentFailed(paymentIntent)
          _history(`${RENDER_URL.PAYMENT_DECLINED}`)
          break;
        case "requires_payment_method":
          dispatch({
            type: STOP_LOADING,
          });
          onPaymentFailed(paymentIntent)
          _history(`${RENDER_URL.PAYMENT_DECLINED}`)
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          dispatch({
            type: STOP_LOADING,
          });
          onPaymentFailed(paymentIntent)
          _history(`${RENDER_URL.PAYMENT_DECLINED}`)
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const onPaymentFailed = (paymentIntent)=>{
    ReactGA.send({
      hitType: "event",
      eventCategory: "Web Vitals",
      eventAction: "PaymentFailed",
      eventLabel: `paymentBy`,
      nonInteraction: true,
      // Built-in params:
      value: paymentIntent.id, // Use delta so the value can be summed.
      // Custom params:
      payment_id: paymentIntent.id,
      payment_user: context.login.userData ? parseInt(context.login.userData.uuid.split("BFCA_")[1]) : null

  })

  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: process.env.REACT_APP_PAYMENT_REDIRECT,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      _history(`${RENDER_URL.PAYMENT_DECLINED}`)
      setMessage(error.message);
    } else {
      _history(`${RENDER_URL.PAYMENT_DECLINED}`)
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const onPaymentSuccess = () => {

    let selectedBoxType = context.login.setSelectedBoxOption;
    console.log(">>>", selectedBoxType);
    if (selectedBoxType.name == context.login.AVATAR_CHOICE.MYSTERY.name) {
      _history('/mbox')
    } else {
      _history('/spin')
    }
  }

  const onUpdatePaymentInfo = (_details) => {
    try {
      let selectedOrderOption = context.login.setSelectedOrderOption;
      let selectedChoiceOption = context.login.setSelectedBoxOption;
      let userData = context.login.userData;
      let amount = _details.amount;
      let uuid = _details.id;


      let data = {
        "uuid": uuid,
        "product_choice": selectedChoiceOption.id,
        "product_type": selectedOrderOption.id,
        "user_id": userData ? parseInt(userData.uuid.split("BFCA_")[1]) : null,
        "amount": amount,
        "currency": "euro",
        "metadata": _details
      }

      updatePaymentInfo(JSON.stringify(data)).then((response) => {


        console.log("payment update response", response);

        if (response.data.success) {
          //props.onSuccess('success', 'success', 'Payment Successful');
          ManageLocalStorage.set(PAYMENT_UUID, uuid);
          dispatch({
            type: PAYMENT_UUID,
            payload: uuid,
          });

          setTimeout(() => {
            dispatch({
              type: STOP_LOADING,
            });
            _history(`${RENDER_URL.PAYMENT_SUCCESS}`)
          }, 1000);
        }

      }).catch((error) => {
        dispatch({
          type: STOP_LOADING,
        });
        console.log("payment update error", error);
      })

    } catch (error) {
      dispatch({
        type: STOP_LOADING,
      });
      console.log("onUpdatePaymentInfo error", error);
    }



  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" style={{ display: displayForm }} onSubmit={handleSubmit}>
      <div className="card shadow-lg">
        <div className="row" style={{ padding: '10px' }}>
          <div className="col-lg-8 col-md-12 col-sm-12">
            {/* <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={(e) => { setEmail(e.value.email) }}
            /> */}
            <AddressElement options={{ mode: 'billing' }}></AddressElement>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button className="btn btn-danger btn-lg" disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
              </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
            <div className="footer_text text-center" style={{ padding: '10px' }}>
              {WEB_STRINGS.PAYMENT_FORM_FOOTER}
            </div>
          </div>
        </div>
      </div>

    </form>
  );
}
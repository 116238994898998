import React, { useState, useEffect, useRef } from "react";
import TwitterLogin from 'react-twitter-auth';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import "./style.css";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import UnLeadHeader from "../common/UnLeadHeader";
import { saveAs } from 'file-saver';
import { TWITTER_USER, LOGOUT, RENDER_URL, LOCALISATION_ARRAY, FROM_DASHBOARD, START_LOADING, STOP_LOADING } from "../common/Constants";
import LocalizedStrings from "react-localization";
import { useSwipeable } from 'react-swipeable';
import { dashboardAttributes, retweetClaimCheck, likeClaimCHeck, getLatestPinnedTweet } from "./DashboardService";
import TwitterButtonComponent from "../twitter/TwitterButtonComponent";
import LocalizationHeader from "../LocalizationHeader/LocalizationHeader";
import { element } from "prop-types";
import { ManageLocalStorage } from "../core/LocalStorage";
import { ManageSessionStorage } from "../core/SessionStorage";



function DashboardNewContainer() {

    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);

    const _history = useNavigate();
    const location = useLocation();

    const mytwitterRef = useRef(null);
    const [twitterUser, setTwitterUser] = useState()
    const [twitterConnected, setTwitterConnected] = useState(false);
    const [tweet_id, setLatestTweetID] = useState(undefined);

    const [mysteryAvatarList, setMysteryAvatarList] = useState([])

    const [socialList, setSocialList] = useState([])
    const [mysteryAvatar, setMysteryAvatar] = useState()


    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    const [language_code, setLanguageCode] = useState(context.login.LANGUAGE_CODE)
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const list = [{ name: WEB_STRINGS.DASHBOARD }, { name: WEB_STRINGS.LOG_OUT }];
    const [selected, setSelected] = useState(list[0]);

    const [showProfileImage, setProfileImage] = useState(true);

    const [currentCount, setCurrentCount] = useState(0);

    const [twitterConnectCoins, setTwitterConnectCoins] = useState(0);
    const [retweetCoins, setRetweetCoins] = useState(0);
    const [tweetLikeCoins, setTweetLikeCoins] = useState(0);


    useEffect(() => {
        setTwitterUser(context.login.TWITTER_USER)
    }, [context.login.TWITTER_USER]);

    useEffect(() => {
        setSelected(list[0])
    }, [context.login.LANGUAGE_CODE]);


    useEffect(() => {
        if (selected.name == WEB_STRINGS.LOG_OUT) {
            clearBrowser();
        }
    }, [selected])

    const clearBrowser = () => {

        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        _history(RENDER_URL.ORDER_OPT_URL);
    }

    useEffect(() => {
        if (context.login.userData) {
            loadMyAvatars();
            loadLatestTweet();
            dispatch({
                type: FROM_DASHBOARD,
                payload: false,
            });
        }
        else {
            dispatch({
                type: FROM_DASHBOARD,
                payload: true,
            });
            _history(RENDER_URL.LOGIN_NEW_URL, { state: { fromDashboard: true } });
        }


    }, []);


    useEffect(() => {

        socialList.forEach(element => {
            if (element.action == "CONNECT") {
                setTwitterConnected(true);
                setTwitterConnectCoins(element.coins);
                dispatch({
                    type: TWITTER_USER,
                    payload: { id: element.uuid },
                });
            }
            else if (element.action == "RETWEET") {
                setRetweetCoins(element.coins);
            }
            else if (element.action == "LIKE") {
                setTweetLikeCoins(element.coins);
            }
        })

    }, [socialList])


    const loadLatestTweet = () => {
        let data = {
            tuser_id: process.env.REACT_APP_BENFICA_TWITTER_ID
        }
        getLatestPinnedTweet(JSON.stringify(data)).then((response) => {
            if (response.data.success) {
                setLatestTweetID(response.data.data.pinned_tweet_id);
            }
        }).catch((error) => {
            console.log("TWEET DATA ERROR ", error);
        })
    }


    const loadMyAvatars = () => {

        dispatch({
            type: START_LOADING,
        });

        dashboardAttributes().then((response) => {

            if (response.data.success) {
                let avatarData = response.data.attributes.avatars;
                let socialData = response.data.attributes.social;

                if (socialData.length == 0) {
                    setTwitterUser();
                }
                else {
                    setSocialList(socialData);

                }

                if (avatarData) {
                    setMysteryAvatarList(avatarData);
                    setCurrentCount(0);
                    setMysteryAvatar(avatarData[0]);
                }
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {
            console.log(error, "ERROR");
            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const onLoadNext = () => {
        let count = currentCount;
        count++;
        if ((count) <= (mysteryAvatarList.length - 1)) {
            setCurrentCount(count);
            console.log(mysteryAvatarList[count]);
            setMysteryAvatar(mysteryAvatarList[count]);
        }
    }


    const onLoadPrev = () => {
        let count = currentCount;
        count--;
        if ((count) >= 0) {
            setCurrentCount(count);
            setMysteryAvatar(mysteryAvatarList[count]);
        }
    }

    const renderAttributes = () => {

        if (mysteryAvatar?.metadata) {
            let count = 0;
            return (
                Object.entries(mysteryAvatar?.metadata).map(([index, object]) => {
                    return <div key={index} className="attribute">
                        <span style={{ color: 'red' }}>{object.name}</span>
                        <span style={{ color: 'black' }}>{object.value ? object.value : 'none'}</span>
                        <span style={{ color: 'black' }}>{object.proportion ? `${object.proportion} %` : ''}</span>
                    </div>
                })
            )
        }

    }

    const onDownloadClick = () => {
        saveAs(showProfileImage ? mysteryAvatar.profile_image : mysteryAvatar.booth_image, `${mysteryAvatar?.name}_${mysteryAvatar?.avatar_id}`)
    }


    const onOrderAgain = () => {

        _history(RENDER_URL.ORDER_OPT_URL);

    }

    const handlers = useSwipeable({
        onSwipedLeft: () => setProfileImage(!showProfileImage),
        onSwipedRight: () => setProfileImage(!showProfileImage),
        swipeDuration: 1000,
        trackMouse: true
    });


    const checkRetweetClaim = () => {
        let data = {
            "tuser_id": twitterUser.id,
            "tweet_id": tweet_id
        }
        retweetClaimCheck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {
                loadMyAvatars();
            } else {
                response.data.message = WEB_STRINGS.CHALLENGE;
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }
    const checkLikeClaim = () => {
        let data = {
            "tuser_id": twitterUser.id,
            "tweet_id": tweet_id
        }
        likeClaimCHeck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {

                loadMyAvatars();

            } else {
                response.data.message = WEB_STRINGS.CHALLENGE;
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const followUsTwitter = () => {

        let data = {
            "tuser_id": twitterUser.twitterProvider.id,
        }
        retweetClaimCheck(JSON.stringify(data)).then((response) => {
            console.log("response", response);
            if (response.data.success) {
                alert(response.data.message)
            } else {
                alert(response.data.message)
            }
        }).catch((error) => {
            console.log("error", error);
        })

    }

    const connectTwitter = () => {
        mytwitterRef.current.click();
    }

    const renderEarnedCoins = () => {
        let count = 0;

        if (socialList.length > 0) {

            socialList.forEach(element => {
                count = count + element.coins
            })

            return count;
        }
        return count;
    }


    const renderMyAvatars = () => {
        return (Object.entries(mysteryAvatarList).map(([index, value]) => {

            console.log(">>>>>>>>>>>>>>>>", value);
            return <div className="col-lg-3 col-md-12 cols-sm-12">
                <div>
                    <img className="justify-content-center" style={{ width: '100%', marginTop: '10px' }} src={`${value?.profile_image}`} alt="image" onClick={() => { setMysteryAvatar(value) }} />
                </div>
            </div>
        }))
    }

    return (
        <>
            <div style={{ display: 'none' }}>
                <TwitterButtonComponent ref={mytwitterRef} />
            </div>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <div className="row red_nav">
                        <div className="col-lg-4 cols-md-4 col-sm-12">
                            <div style={{ padding: '10px' }}>
                                <img height="60px" src="../assets/images/Logo-Royale_v2.png" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center d-none d-lg-block">
                            <div style={{ padding: '10px' }}>
                                <img src="../assets/images/LOGO_BENFICA.png" width="60px" height="60px" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div style={{ padding: '20px' }}>
                                <LocalizationHeader />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div className="d-none d-lg-block float-lg-end" style={{ padding: '10px' }}>
                                <Dropdown value={selected} onChange={(e) => setSelected(e.value)} options={list} optionLabel="name" dropdownIcon="pi pi-home" />
                            </div>
                            <div className="col-sm-12 d-lg-none">
                                <Dropdown className="form-control" value={selected} onChange={(e) => setSelected(e.value)} options={list} optionLabel="name" dropdownIcon="pi pi-home" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {mysteryAvatarList.length > 0 ?
                            <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-lg-6 text-center" {...handlers} >
                                        <div className="card shadow-lg" id="xxx" style={{ height: '100%', minHeight: '500px' }}>
                                            {
                                                showProfileImage ?
                                                    <div className="bg-image" style={{ background: `url(${mysteryAvatar?.profile_image})` }} >
                                                    </div>
                                                    :
                                                    {/* <div className="bg-image booth" style={{ background: `url(${mysteryAvatar?.booth_image})` }} onClick={() => { setProfileImage(!showProfileImage) }} >
                                                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                            <div style={{ bottom: '0', left: '0', width: '90%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                <span class="dot"></span>
                                                                <span class="dot_selected"></span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                            }
                                            <div className="download_bottom" style={{ marginLeft: '10px', marginTop: '-20px' }}>
                                                <div>
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.DOWNLOAD_IMAGES} onClick={() => { onDownloadClick() }}></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="card shadow-lg" style={{ height: '100%', minHeight: '500px' }}>
                                                <div className="row p-2">
                                                    <div className="col">
                                                        <div className="season_title">{WEB_STRINGS.SEASON} 01</div>
                                                        <div className="season_avatar_no">{mysteryAvatar.name}</div>
                                                    </div>
                                                    {/*  <div className="col-auto">
                                                        <img className="title_logo" src="../assets/images/logo.png" />
                                                    </div> */}
                                                </div>
                                                <hr></hr>
                                                <div className="row">
                                                    <TabView>
                                                        <TabPanel header={WEB_STRINGS.TASK}>
                                                            <div className="task_list" style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '400px' }}>

                                                                {twitterConnected ?
                                                                    <div className="row">
                                                                        <div className="col-lg-8 col-sm-8">
                                                                            <h5 className="headline"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> {WEB_STRINGS.TWITTER_CONNECTED}</h5>
                                                                            <p style={{ marginLeft: '8%' }}>
                                                                                <span className="task_desc">{WEB_STRINGS.CONNECT_TWITTER_TASK}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                                            <p className="headline"><b>{twitterConnectCoins} {WEB_STRINGS.COINS}</b></p>
                                                                            <input type="button" className="btn btn-sm btn-outline-danger" value={WEB_STRINGS.CONNECTED} disabled />

                                                                        </div>
                                                                        <hr className="mt-2"></hr>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-lg-8 col-sm-8">
                                                                                <h5 className="headline"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> {WEB_STRINGS.CONNECT_TWITTER}</h5>
                                                                                <p style={{ marginLeft: '8%' }}>
                                                                                    <span className="task_desc">{WEB_STRINGS.CONNECT_TWITTER_TASK}</span></p>
                                                                            </div>
                                                                            <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                                                <TwitterButtonComponent />
                                                                            </div>
                                                                            <hr className="mt-2"></hr>
                                                                        </div>
                                                                    </>
                                                                }

                                                                <div className="row">
                                                                    <div className="col-lg-8 col-sm-8">
                                                                        <h5 className="headline"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> {WEB_STRINGS.TWITTER_RETWEETS}</h5>
                                                                        <p style={{ marginLeft: '8%' }}>
                                                                            <a href={`https://twitter.com/VictorySLB/status/${tweet_id}`}>{WEB_STRINGS.TWITTER_TASK_2}</a>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                                        <p className="headline"><b>{retweetCoins} {WEB_STRINGS.COINS}</b></p>
                                                                        <input type="button" className="btn btn-sm btn-outline-danger" value={WEB_STRINGS.CLAIM} onClick={() => { checkRetweetClaim() }} />
                                                                    </div>
                                                                    <hr className="mt-2"></hr>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-8 col-sm-8">
                                                                        <h5 className="headline"><i style={{ fontSize: '20px' }} className="pi pi-twitter icon_decoration"></i> {WEB_STRINGS.LIKE_THIS_TWEET}</h5>
                                                                        <p style={{ marginLeft: '8%' }}>
                                                                            <a href={`https://twitter.com/VictorySLB/status/${tweet_id}`}>{WEB_STRINGS.LIKE_THIS_TWEET_TASK}</a>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-4 center-cell text-center">
                                                                        <p className="headline"><b>{tweetLikeCoins} {WEB_STRINGS.COINS}</b></p>
                                                                        <input type="button" className="btn btn-sm btn-outline-danger" value={WEB_STRINGS.CLAIM} onClick={() => { checkLikeClaim() }} />
                                                                    </div>
                                                                    <hr className="mt-2"></hr>
                                                                </div>



                                                            </div>
                                                        </TabPanel>
                                                    </TabView>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <p className="float-end p-3 color-red">{WEB_STRINGS.COINS_EARNED} : {renderEarnedCoins()}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div>
                                        <img className="title_logo" src="../assets/images/logo.png" /><span className="text_my_avatar">{WEB_STRINGS.MY_AVATARS}</span>
                                    </div>
                                </div>

                                <div className="row" style={{ maxHeight: '400px', overflowX: 'hidden', overflowY: 'scroll' }}>


                                    {renderMyAvatars(mysteryAvatarList)}


                                    <div className="col-lg-3 col-md-12 cols-sm-12">
                                        {/* <div className="card shadow-lg" style={{ height: '205px', minWidth: '100%', overflowX: 'hidden', overflowY: 'scroll', marginTop: '10px' }}>


                                            <div className="col-sm-12" style={{ marginTop: "60px" }}>
                                                <p className="order_now_title">{WEB_STRINGS.GET_ANOTHER_AVATAR}</p>
                                                <button type="button" className="btn btn-danger btn_order_now" onClick={() => { onOrderAgain() }}>{WEB_STRINGS.ORDER_NOW}</button>
                                            </div>


                                        </div> */}
                                        <div className="card shadow-lg _card_">
                                            <div className="row justify-content-center">
                                                <div className="col-sm-12" style={{marginTop:'60px'}}>
                                                    <p className="order_now_title">{WEB_STRINGS.GET_ANOTHER_AVATAR}</p>
                                                    <div style={{ textAlign: 'center',marginLeft:'-20px' }}>
                                                        <button type="button" className="btn btn-danger btn_order_now" onClick={() => { onOrderAgain() }}>{WEB_STRINGS.ORDER_NOW}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>


                                <div className="row">
                                    <div className="col-sm-12">
                                        <p></p>
                                    </div>
                                </div>
                            </div>

                            :
                            <div className="row h-100 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                    <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="sign_header">
                                                        {WEB_STRINGS.NO_AVATARS_TO_DISPLAY}
                                                    </div>
                                                    <br></br>
                                                    <Button label={WEB_STRINGS.ORDER_NOW} className="social_button" severity="danger" onClick={() => { onOrderAgain() }} />
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        }


                    </div>

                </div>

            </section>









        </>
    )
}
export default DashboardNewContainer;
import { httpCall, httpFormCall, httpGetCall, httpLoginCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const getPricingDetails = params => {
    return httpGetCall({
        url: API_URL.PRICING_DETAILS,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};




import React, { useState, useEffect } from "react";


let select_avatar_css = {
    selected: 'card payment_border',
    not_selected: 'card  '
}
function SelectedAvatarComponent(props) {

    return (
    
            <div className="col-lg-4" style={{marginTop:'10px',marginBottom:'10px'}} onClick={()=>{props.onclickfn()}}>
                <div className={(props.isSelected == props.id)? select_avatar_css.selected : select_avatar_css.not_selected}>
                    {
                        (props.placeholder == true) ? 
                        <img src={"./assets/images/logo.png"} style={{opacity:'0.5',margin:'10px'}} className="img img-responsive" />
                        :
                        <img src={props.obj.booth_image} style={{margin:'10px'}} className="img img-responsive" />
                    }
                    
                </div>
            </div>
        
    )
}
export default SelectedAvatarComponent;
import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import FooterContainer from "../footer/FooterContainer";
import { useNavigate } from "react-router-dom";
import { forgotApi } from "../SignIn/SignService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { validateEmail, START_LOADING, RENDER_URL, LOCALISATION_ARRAY, STOP_LOADING } from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Toast } from "primereact/toast";
import LocalizedStrings from "react-localization";

function ForgotPassword(props) {

    const toast = useRef(null);
    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const [username, setUsername] = useState('')
    const [username_error, setUserError] = useState("");
    const [error_msg, setErrorMsg] = useState("")



    const clearValidation = () => {
        setUserError("");
    }




    const onResetAction = () => {
        clearValidation();
        if (username.length <= 0) {
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else if (!validateEmail(username)) {
            setUserError(WEB_STRINGS.ENTER_VALID_EMAIL);
            return;
        }
        else {
            backgroundForgot(username);
        }
    }


    const backgroundForgot = (username) => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            email: username,
            language: `${context.login.LANGUAGE_CODE}`
        }
        forgotApi(param).then((response) => {

            if (response.data.success) {

                let text = response.data.message;
                const myArray = text.split("An email with password reset link has been sent to");
                setErrorMsg(`${WEB_STRINGS.RESET_PASSWORD_MESSAGE} ${myArray[1]}`)
            }
            else {
                setErrorMsg(WEB_STRINGS.ERROR)
            }


            dispatch({
                type: STOP_LOADING
            });


        }).catch((error) => {
            console.log("ERROR", error);

            dispatch({
                type: STOP_LOADING
            });

        })
    }


    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <Toast ref={toast} />
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                    <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="sign_header">{WEB_STRINGS.FORGOT_PASSWORD}</div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 px-2">
                                                    <div class="form-group">
                                                        <label for="exampleFormControlInput1">Email</label>
                                                        <input type="email" class="form-control form-control-md" id="exampleFormControlInput1" placeholder={WEB_STRINGS.EMAIL_PLACEHOLDER} onChange={(e) => setUsername(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{username_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: '15px', marginBottom: '20px' }}>
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.RESET_PASSWORD_BTN} onClick={() => { onResetAction() }} />
                                                </div>
                                                {
                                                    error_msg && <div className="form-group">
                                                        <span className="p-float-label text-center">
                                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                                        </span>
                                                    </div>
                                                }

                                                <div className="row text-center">
                                                    <p>
                                                        <a style={{ marginLeft: '5px' }} href={RENDER_URL.LOGIN_NEW_URL} target="_self">{WEB_STRINGS.LOGIN}</a>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        </>
    )
}

export default ForgotPassword;
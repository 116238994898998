import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Button } from "primereact/button";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { RENDER_URL,LOCALISATION_ARRAY } from "../common/Constants";
import LocalizedStrings from "react-localization";


function PaymentDeclined(props) {


    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);

    const onReturnToMainClick = ()=>{
        _history(`${RENDER_URL.ORDER_OPT_URL}`);
    }




    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">

                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card shadow-lg">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <img className="img img-responsive" style={{padding:'5px',marginBottom:'10px'}} src="../assets/images/cancel.png" width="93px" height="93px" />
                                                    <p className="payment_success_title">{WEB_STRINGS.PAYMENT_FAILED_TITLE}</p>
                                                    <p className="payment_success_desc">{WEB_STRINGS.PAYMENT_FAILED_DESC}</p>
                                                    
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.RETURN_TO_MAINSCREEN} onClick={()=>{ onReturnToMainClick() }}/>

                                                      
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default PaymentDeclined;
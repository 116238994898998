import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import FooterContainer from "../footer/FooterContainer";
import { useNavigate,useSearchParams } from "react-router-dom";
import { changePassword } from "../login/LoginService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { validateEmail, START_LOADING,STOP_LOADING,RENDER_URL, LOCALISATION_ARRAY, USER_DATA } from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage";
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { Toast } from "primereact/toast";
import LocalizedStrings from "react-localization";

function ResetPassword(props) {

    const toast = useRef(null);
    const _history = useNavigate();
    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext)

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);


    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
   
    const [password, setPassword] = useState("");
    const [password_error, setPasswordError] = useState("");

    const [error_msg, setErrorMsg] = useState()
    const [success_msg, setSuccessMsg] = useState()


    useEffect(()=>{

        ManageLocalStorage.delete(USER_DATA);

    },[]);

    const clearValidation = () => {
        setPasswordError("");
        setErrorMsg("");
    }


    const resetApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const resetData = {
            reset_token: code,
            password: password,
        }

        changePassword(resetData).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });
                setPassword("");
                setSuccessMsg(response.data.message)

                _history(RENDER_URL.LOGIN_NEW_URL);
            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.error);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }


    const onResetAction = () => {
        clearValidation();
       
        if(password.length <= 0){
            setPasswordError(WEB_STRINGS.PASSWORD_VALIDATION)
        }
        else if(password.length <=8 ){
            setPasswordError(WEB_STRINGS.PASSWORD_LENGTH_VALIDATION);
        }
        else{
            resetApi();
        }

    }



    return (
        <>
            <section className="section white_back_main">

                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <Toast ref={toast} />
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            <div className="row h-75 d-flex align-items-center justify-content-center">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12"></div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                    <img src="../assets/images/logo.png" width="100px" className="img img-responsive" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="sign_header">{WEB_STRINGS.RESET_TITLE}</div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 px-2">
                                                    <div class="form-group">
                                                        <label for="inputPassword2" class="sr-only">Password</label>
                                                        <input type="password" class="form-control" id="inputPassword2" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                        <small id="password-help" className="p-error block">{password_error}</small>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: '15px', marginBottom: '20px' }}>
                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.RESET_PASSWORD_BTN} onClick={() => { onResetAction() }} />
                                                </div>
                                                {
                                                    error_msg && <div className="form-group">
                                                        <span className="p-float-label text-center">
                                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                                        </span>
                                                    </div>
                                                }

                                                <div className="row text-center">
                                                    <p>{WEB_STRINGS.CLICK_HERE_TO_LOGIN}<a style={{ marginLeft: '5px' }} href={RENDER_URL.LOGIN_NEW_URL} target="_self">{WEB_STRINGS.LOGIN}</a></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        </>
    )
}

export default ResetPassword;
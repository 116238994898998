import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SelectedAvatarComponent from "./SelectedAvatarComponent";
import { SELECTED_AVATAR, START_LOADING, STOP_LOADING, LOCALISATION_ARRAY } from "../common/Constants";
import { claimAvatarEagleApi, getAvatarEagleApi } from "./SpinBoxService";
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import UnLeadHeader from "../common/UnLeadHeader";
import LogoHeader from "../common/LogoHeader";
import { saveAs } from 'file-saver';
import '../Mysterybox/AssemblyCSS.css';
import LocalizedStrings from "react-localization";
import { useSwipeable } from 'react-swipeable';

function SpinBoxNewContainer() {

    const context = React.useContext(GlobalContext);
    const dispatch = React.useContext(GlobalDispatchContext);
    const _history = useNavigate();

    const [test, setTestArray] = useState([0, 1, 2, 3, 4, 5])
    const [position, setPosition] = useState('bottom');
    const [inside, setInside] = useState(true);
    const [selectedAvatarType, setSelectAvatar] = useState(SELECTED_AVATAR.FIRST)
    const [count, setCount] = useState(0);
    const [showClaimButton, setShowClaimButton] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [spinCounter, setSpinCounterValue] = useState(0);
    const [TempavatarsList, TempsetAvatarList] = useState([]);
    const [avatarsList, setAvatarList] = useState([]);
    const [collectedAvatars, setCollectedAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatarFromList] = useState(undefined);
    const [showMask, setMaskVisibility] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinButtonStatus, setSpinButtonStatus] = useState(false);
    const [enableClaim, setEnableClaimButton] = useState(false);
    const [showProfileImage, setProfileImage] = useState(true);

    let WEB_STRINGS = new LocalizedStrings(LOCALISATION_ARRAY);
    WEB_STRINGS.setLanguage(context.login.LANGUAGE_CODE);


    useEffect(() => {
        loadAvatarsFromApi();
    }, []);

    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    useEffect(() => {
        renderCollectAvatars();

    }, [selectedAvatar])


    useEffect(() => {
        const timer = setTimeout(() => {

            let _rightNowAvatar = randomIntFromInterval(0, avatarsList.length - 1);
            setSpinButtonStatus(false);
            if (avatarsList[_rightNowAvatar] != undefined) {
                collectedAvatars.push(avatarsList[_rightNowAvatar]);
                setCollectedAvatars(collectedAvatars);
                setSelectedAvatarFromList(collectedAvatars[collectedAvatars.length - 1])
                if (collectedAvatars.length > 5) {
                    setShowClaimButton(true);
                }
                let oldList = avatarsList;
                oldList.splice(_rightNowAvatar, 1);
                setAvatarList(oldList);
                setMaskVisibility(false);
                setSpinnerVisible(false);
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [startTimer]);


    const loadAvatarsFromApi = () => {
        let P_UUID = context.login.PAYMENT_UUID;
        let choice = context.login.setSelectedBoxOption.id;
        let category = context.login.setSelectedOrderOption.id;
        let param = {
            payment_id: P_UUID,
            choice: choice,
            category: category
        }

        getAvatarEagleApi(param).then((response) => {
            if (response.data.success) {
                let avatar = response.data.attributes.avatars;
                if (avatar.length > 0) {

                    let eagle_avatar = avatar;
                    setAvatarList(eagle_avatar);
                    setTimeout(() => {
                        setSpinButtonStatus(false);
                        let _rightNowAvatar = randomIntFromInterval(0, eagle_avatar.length - 1);
                        if (eagle_avatar[_rightNowAvatar] != undefined) {
                            collectedAvatars.push(eagle_avatar[_rightNowAvatar]);
                            setCollectedAvatars(collectedAvatars);
                            setSelectedAvatarFromList(collectedAvatars[collectedAvatars.length - 1])
                            let oldList = eagle_avatar;
                            let mian_array = [...eagle_avatar]
                            oldList.splice(_rightNowAvatar, 1);
                            setAvatarList(oldList);
                            console.log("AGAIN AGAIN")
                            TempsetAvatarList(mian_array);

                            setMaskVisibility(false);
                            setSpinnerVisible(false);
                        }
                    }, 5000);
                }
            }
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });
        });
    }

    const onSpin = () => {

        //setMaskVisibility(true);
        setSpinnerVisible(true);
        setSpinCounterValue(spinCounter + 1);
        setStartTimer(!startTimer);
        setSpinButtonStatus(true)

    }


    const onClaimMystreyEagle = () => {

        dispatch({
            type: START_LOADING,
        });

        let P_UUID = context.login.PAYMENT_UUID;
        let params = {
            payment_id: P_UUID,
            avatar_id: selectedAvatar.avatar_id,
            language: context.login.LANGUAGE_CODE
        }

        claimAvatarEagleApi(params).then((response) => {
            console.log(response);
            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });
                _history('/thankyou', { state: { selectedData: selectedAvatar } })
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

            console.log(error);

        })

    }

    const onSelectAvatar = (id_value) => {
        setCount(count + 1);

        let x = undefined;
        collectedAvatars.map((e) => {
            if (e.avatar_id == id_value) {
                x = e;
            }
        })
        if (x) {
            setSelectedAvatarFromList(x)
        }
    }

    const renderAttributes = () => {

        try {
            if (selectedAvatar != undefined) {
                let count = 0;
                return (
                    Object.entries(selectedAvatar.metadata).map(([index, object]) => {
                        return <div key={index} className="attribute">
                            <span style={{ color: 'red' }}>{object.name}</span>
                            <span style={{ color: 'black' }}>{object.value ? object.value : 'none'}</span>
                            <span style={{ color: 'black' }}>{object.proportion ? `${object.proportion} %` : ''}</span>
                        </div>
                    })
                )
            }
        } catch (error) {
            console.log(">>renderAttributes>>>", selectedAvatar);
        }

    }

    const onDownloadClick = () => {
        saveAs(selectedAvatar.booth_image, `${selectedAvatar.name}_${selectedAvatar.avatar_id}`)
    }




    const renderCollectAvatars = () => {


        return test.map((value, index) => {
            if (collectedAvatars[value] != undefined) {
                let x = collectedAvatars[value];
                return <SelectedAvatarComponent placeholder={false} key={index} isSelected={selectedAvatar.avatar_id} id={x.avatar_id} obj={x} onclickfn={() => { onSelectAvatar(x.avatar_id) }} />
            }
            else {
                return <SelectedAvatarComponent placeholder={true} key={index} id={1112} onclickfn={() => { }} />
            }
        })

        /* return collectedAvatars.map((value, index) => {
            return <SelectedAvatarComponent key={index} isSelected={selectedAvatar.avatar_id} id={value.avatar_id} onclickfn={() => { onSelectAvatar(value.avatar_id) }} />
        }) */

    }


    const renderProfileImage = () => {
        const width = document.getElementById('xxx').offsetWidth

        return (
            Object.entries(TempavatarsList).map(([index, object]) => {
                return <div className="bg-img-ccc" style={{ width: width, background: `url(${object?.profile_image})` }}>
                    {/* <img key={index} src={object.booth_image} alt=""  /> */}
                </div>
            })
        )


    }


    const handlers = useSwipeable({
        onSwipedLeft: () => setProfileImage(!showProfileImage),
        onSwipedRight: () => setProfileImage(!showProfileImage),
        swipeDuration: 1000,
        trackMouse: true
    });




    return (
        <>
            <section className="section white_back_main">
                <div className="container-fluid">
                    <UnLeadHeader></UnLeadHeader>
                    <div className="row">
                        <div className="col-sm-12 main_section">
                            <LogoHeader></LogoHeader>
                            {
                                !showMask ?
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                            <div className="AvatarChooseTitle" style={{ fontSize: '30px' }}>{WEB_STRINGS.SPIN_AND_CHOOSE}</div>
                                            <div className="AvatarChooseDesc">{WEB_STRINGS.SPIN_AND_CHOOSE_DESC}</div>
                                        </div>

                                        <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2" style={{ marginTop: '2%' }}>
                                            <div className="row">
                                                <div className="col-lg-6 text-center">
                                                    <div className="card shadow-lg" id="xxx" style={{ padding: '10px', height: '100%', minHeight: '500px' }}>

                                                        {spinnerVisible ? <div className="marquee">
                                                            <div className="marquee__group">
                                                                {renderProfileImage()}
                                                            </div>
                                                        </div>
                                                            :

                                                            showProfileImage ?
                                                                <div className="bg-image" style={{ background: `url(${selectedAvatar?.profile_image})` }} onClick={() => { setProfileImage(!showProfileImage) }} >
                                                                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                        <img src="../assets/images/slide.png" width="12%" height="auto" style={{ float: 'left', paddingLeft: '10px', paddingTop: '10px' }} />
                                                                        <div style={{ bottom: '20px', left: '0', width: '100%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                            <span class={showProfileImage ? "dot_selected" : "dot"}></span>
                                                                            <span class="dot"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="bg-image booth" style={{ background: `url(${selectedAvatar?.booth_image})`, borderRadius: '10px' }} onClick={() => { setProfileImage(!showProfileImage) }} >
                                                                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                        <div style={{ bottom: '-20px', left: '0', width: '90%', position: 'absolute', backgroundColor: '#f7f7f71f' }}>
                                                                            <span class="dot"></span>
                                                                            <span class="dot_selected"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }



                                                        <div className="download_bottom">
                                                            <div>
                                                                {showClaimButton ?
                                                                    <Button className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.CONTINUE_WITH_SELECTED_AVATAR} onClick={() => { onClaimMystreyEagle() }}></Button>
                                                                    :
                                                                    <Button disabled={spinButtonStatus} className="btn btn-lg btn-sm btn-md btn-danger btn_create_account" label={WEB_STRINGS.SPIN_TO_NEXT_AVATAR} onClick={() => { onSpin() }}></Button>
                                                                }
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="card shadow-lg">
                                                            <div className="attribute_div">
                                                                <div className="row back_white">
                                                                    <div className="col-auto p-0">
                                                                        <img src="../assets/images/logo.png" width='50px' />
                                                                    </div>
                                                                    <div className="col p-0">
                                                                        <h6 style={{marginTop:'2%'}}>{selectedAvatar ? selectedAvatar.name : ""}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin_test">
                                                                    <div className="col-sm-12">
                                                                        <div style={{ paddingRight: '40px', position: 'relative' }}>
                                                                            <div className='attributes'>
                                                                                {
                                                                                    renderAttributes()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: '10px' }}>
                                                        <div className="card shadow-lg">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    {
                                                                        renderCollectAvatars(collectedAvatars, selectedAvatar)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    :
                                    <div className="row h-100 d-flex align-items-center justify-content-center">
                                        <div className="pl">
                                            <svg className="pl__rings" viewBox="0 0 128 128" width="128px" height="128px">
                                                <g fill="none" strokeLinecap="round" strokeWidth="4">
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" strokeDasharray="50 240" />
                                                        <ellipse className="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" strokeDasharray="25 265" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(0)">
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                    </g>
                                                    <g className="pl__ring" transform="rotate(180)">
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                        <ellipse className="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" strokeDasharray="1 289" strokeWidth="8" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="pl__nucleus">
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                                <div className="pl__nucleus-particle"></div>
                                            </div>
                                        </div>
                                        <p className="text-center privacy_policy">{WEB_STRINGS.ASSEMBLE}</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SpinBoxNewContainer